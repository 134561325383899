import { Redirect, generateString, getEmergentPayCredentials, getHubtelCredentials, hubtelNetworkType, stripZeroPhoneNumber } from '../../../utils/mvest-utils/util';

let mVestData;
if (typeof window !== 'undefined') {
    mVestData = JSON.parse(window.localStorage.getItem('mVestData'));
} else {
    // Handle the case when localStorage is not available
    mVestData = null; 
}

export const initiatePayment = async () => {
    const _data = JSON.parse(localStorage.getItem('mVestData'));
    const payment_url = 'https://test-payment.oldmutual.com.gh/ProcessPayment';
    const postData = {
        name: `${_data?.schemeDetails?.first_name} ${_data?.schemeDetails?.other_names}`,
        email: _data?.schemeDetails?.email_address,
        mobile: _data?.schemeDetails?.mobile_number, 
        return_url: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://d1b2sh1ghml15h.cloudfront.net/mvest' : 'https://www.oldmutual.com.gh/mvest',
        currency: 'GHS',
        amount: _data?.contributionDetails?.monthly_contribution,
        order_id: `OMPF/MV/${_data?.schemeDetails?.id_number}/${generateString(4)}`, //unique
        // order_desc: `OM MVest Subscription for ${_data?.schemeDetails?.first_name} ${_data?.schemeDetails?.other_names}`,    // custom description
        order_desc: `${localStorage.getItem('OMMV_policy_no')}|mvest`,    // member no
    };
    try {
        const response = await fetch(payment_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-Key': 'hgjku78uo8-cte736565r7656x4538uip80v-0-ty47536',
            },
            body: JSON.stringify(postData),
        });
        const result = await response.json();
        if (result?.status_code === 1) {
            // Store the result.trans_ref_no for Reconciliation
            // console.log(result?.redirect_url);
            Redirect(result?.redirect_url);
        } else {
            console.log(result);
        }
    } catch (error) {
        console.error('An error occurred while making the POST request:', error);
        throw error;
    }
};


export const consentToRecurringPayment = async () =>{
    const consent_url = 'https://test-payment.oldmutual.com.gh/CustomerConsent';
    const payload = {
        member_no:  `OMPF/MV/${mVestData?.schemeDetails?.id_number}`,
        firstname: mVestData?.schemeDetails?.first_name,
        lastname: mVestData?.schemeDetails?.other_names,
        email: mVestData?.schemeDetails?.email_address,
        phone: mVestData?.schemeDetails?.mobile_number,
        network: 'MTN',
        amount: mVestData?.contributionDetails?.monthly_contribution,
        frequency: 'monthly',
        address: mVestData?.schemeDetails?.postal_address,
    };
    try {
        const response = await fetch(consent_url, {
            method: 'POST',
            // cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'X-API-Key': 'hgjku78uo8-cte736565r7656x4538uip80v-0-ty47536',
                // "Access-Control-Allow-Origin": "*"
            },
            mode: 'no-cors',
            // credentials: "include",
            body: JSON.stringify(payload),
        });
        const result = await response.json();
        console.log(result)
        if (result?.statusCode == '1' || result?.message === 'Success') {
            // console.log("consent recurring payment successfull");
            // console.log(result)
            return result
        } else {
            console.log(result);
        }
    } catch (error) {
        console.error('An error occurred while making the POST request for recurring payment:', error);
        throw error
    }
}

export const initiateHubtelPayment = async () => {
    const _data = JSON.parse(localStorage.getItem('mVestData'));
    
    const payload = {
        channel: hubtelNetworkType(localStorage.getItem('OMMV_Telco')),
        name: `${_data?.schemeDetails?.first_name} ${_data?.schemeDetails?.other_names}`,
        description: localStorage.getItem('OMMV_policy_no'),
        phone: '233' + stripZeroPhoneNumber(_data?.schemeDetails?.momo_number.toString()), 
        amount: mVestData?.contributionDetails?.monthly_salary,
        proposalNumber: localStorage.getItem('OMMV_policy_no'),  
    };
    try {
        const response = await fetch(getHubtelCredentials().payment_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getHubtelCredentials().API_KEY,
            },
            body: JSON.stringify(payload),
        });
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('An error occurred while making the POST request for payment::', error);
        throw error;
    }
}

export const hubtelPaymentVerifyOTP = async (otp, otpPrefix, clientReferenceId) => {
    const payload = {
        otpCode: `${otpPrefix} ${otp}`,
        clientReferenceId: clientReferenceId
    }
    try {
        const response = await fetch(getHubtelCredentials().payment_verifyOTP, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getHubtelCredentials().API_KEY,
            },
            body: JSON.stringify(payload),
        });
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('An error occurred while making the POST request for payment::', error);
        throw error;
    }
}

export const hubtelPaymentVerifyTransaction = async (clientReferenceId) => {
    
    try {
        const response = await fetch(`${getHubtelCredentials().baseURL}api/v1/hubteldirectdebitpayment/directdebitcharge-status-check/${clientReferenceId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getHubtelCredentials().API_KEY,
            },
        });
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('An error occurred while making the POST request for debit charge check::', error);
        throw error;
    }
}