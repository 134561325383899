import { useEffect, useState } from 'react';

const useUrlParams = () => {
  const [params, setParams] = useState({});

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const urlObj = new URL(window.location.href);
      const urlSearchParams = urlObj.searchParams;
      const newParams = {};

      for (let [key, value] of urlSearchParams.entries()) {
        newParams[key] = value;
      }

      setParams(newParams);
    }
  }, []);

  return params;
};

export default useUrlParams;
