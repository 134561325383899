import React, { useContext } from 'react';
import { PAGE_INDICES } from '../constants/routing';
import { MVestContext } from '../context/MVestContext';
import SchemeDetailsPage from './schemeDetailsPage/SchemeDetails';
import ContributionDetailsPage from './ContributionDetailsPage/ContributionDetails';
import BeneficiariesPage from './beneficiariesPage/BeneficiariesPage';
import BeneficiariesSplitPage from './beneficiariesSplitPage/BeneficiariesSplitPage';
import ReviewPage from './reviewPage/ReviewPage';
import SuccessPage from './successPage/successPage';

const renderComponent = (action) => {
    switch (action) {
    case PAGE_INDICES.SCHEME_DETAILS:
        return <SchemeDetailsPage />;
    case PAGE_INDICES.CONTRIBUTION_DETAILS:
        return <ContributionDetailsPage />;
    case PAGE_INDICES.BENEFICIARIES:
        return <BeneficiariesPage />;
    case PAGE_INDICES.BENEFICIARIES_SPLIT:
        return <BeneficiariesSplitPage />;
    case PAGE_INDICES.REVIEW_APPLICATION:
        return <ReviewPage />;
    case PAGE_INDICES.SUCCESS:
        return <SuccessPage />;
    default:
        return null;
    }
};

const Outlet = () => {
    const { activePageIndex } = useContext(MVestContext);
    return <> {renderComponent(activePageIndex)}</>;
};

export default Outlet;
