import React from 'react';
import { OmApplicationPage } from '@om/component-library-react';
import ApplicationLayout from '../../../../layouts/ApplicationLayout';
import ApplicationWizardHeader from '../applications/mVest/ApplicationWizardHeader';
import ApplicationWizardFooter from '../applications/mVest/ApplicationWizardFooter';
import { MVestProvider } from '../applications/mVest/context/MVestContext';
import Outlet from '../applications/mVest/pages/Outlet';
const MVestPage = () => {
    return (
        <MVestProvider>
            <ApplicationLayout>
                <OmApplicationPage>
                    <div slot="header">
                        <ApplicationWizardHeader callMeBack={{}} />
                    </div>
                    <div slot="content-no-sidebar">
                        <Outlet />
                    </div>
                    <div slot="footer">
                        <ApplicationWizardFooter />
                    </div>
                </OmApplicationPage>
            </ApplicationLayout>
        </MVestProvider>
    );
};

export default MVestPage;
