import React, { useContext, useState } from "react";
import { DEFAULT_VALIDATION_DELAY } from "../../../../../ghana/configs/mvest-configs/config";
import {
  OmFormInputFieldWrapper,
  OmFormDropdownFieldWrapper,
  OmApplicationBeneficiaryDetails,
  OmSideDrawer,
} from "@om/component-library-react";
import OmButton from "../../../../../../../components/forms/OmButton";
import { MVestContext } from "../../context/MVestContext";
const referenceRelationship = require("../../constants/refeferenceData/relationships");
import { OmdsDatePickerField } from "@mom-omds/react-design-system-components/dist/generated/components";
import { isInputValid,  areAllInputsInvalid } from "../../../../../ghana/utils/mvest-utils/util";
const EditBeneficiariesDetailsSideDrawer = () => {
  const {
    isEditDrawerOpen,
    beneficiaryListData,
    toggleEditDrawer,
    getBeneficiaryToEdit,
    updateObjectInArray,
    idToEdit,
  } = useContext(MVestContext);
  const [beneficiary, setBeneficiary] = useState(getBeneficiaryToEdit());


  const cancelAndCloseEditing = () => {
    toggleEditDrawer();
  };



  const handleChange = (e) => {
    const value = e.detail;
    const input = e.target.name;
    let formattedValue = "";
    if (input === "MobileNumber") {
      formattedValue = value.startsWith("+233") ? value : "+233" + value;;
    } else {
      formattedValue = value;
    }
    setBeneficiary({
      ...beneficiary,
      [input]: formattedValue,
    });
  };

  const id = idToEdit
  const saveBeneficiary = () => {
    updateObjectInArray(beneficiaryListData, { id, ...beneficiary });
    toggleEditDrawer();
  };

  return (
    <OmSideDrawer
      onOnSideDrawerClosed={() => {
        toggleEditDrawer();
      }}
      open={isEditDrawerOpen}
    >
      <div slot="side-drawer-content">
        <OmApplicationBeneficiaryDetails>
          <div slot="om-application-beneficiary-details">
            <div>
              <h5
                className="no-top-margin side-drawer-header"
                id="beneficiaries-side-drawer"
              >
                <strong>Edit beneficiary details </strong>
              </h5>

              <OmFormInputFieldWrapper
                label="First name"
                id="firstname"
                name="firstname"
                isSensitive
                labelAlwaysShow={true}
                required
                formId="beneficiaryDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. John"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={beneficiary?.firstname}
                state={isInputValid(beneficiary?.first_name)}
                errorMessage={
                  isInputValid(beneficiary?.first_name) === "error"
                    ? "Please enter first name"
                    : undefined
                }
                onPersist={(e) => handleChange(e)}
              />
              <OmFormInputFieldWrapper
                label="Last name"
                id="OtherName"
                name="OtherName"
                isSensitive
                required
                formId="beneficiaryDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. Doe"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={beneficiary?.OtherName}
                state={isInputValid(beneficiary?.OtherName)}
                errorMessage={
                  isInputValid(beneficiary?.OtherName) === "error"
                    ? "Please enter last name"
                    : undefined
                }
                onPersist={(e) => handleChange(e)}
              />
              <OmFormDropdownFieldWrapper
                id="Relationship"
                name="Relationship"
                useNativeMobileDropdown
                required
                size="full"
                formId="beneficiaryDetailsForm"
                placeholder="Relationship"
                align="left"
                type="text"
                value={beneficiary?.Relationship}
                state={isInputValid(beneficiary?.sex)}
                errorMessage={
                  isInputValid(beneficiary?.sex) === "error"
                    ? "Please select relationship"
                    : undefined
                }
                options={
                  referenceRelationship &&
                  JSON.stringify(
                    referenceRelationship.RELATIONSHIPS.map((option) => ({
                      value: option.fullcode,
                      label: option.description,
                    }))
                  )
                }
                onOnSelect={(e) =>
                  setBeneficiary({
                    ...beneficiary,
                    Relationship: e.detail.value,
                  })
                }
              />

              <OmdsDatePickerField
                label="Date of birth"
                value={beneficiary?.birth_date}
                hasError={isInputValid(beneficiary?.birth_date) === "error"}
                onPersist={(e) =>
                  setBeneficiary({
                    ...beneficiary,
                    birth_date: e.detail,
                  })
                }
                placeholder="e.g. 1980-05-05"
              >
                {isInputValid(beneficiary?.birth_date) === "error" ? (
                  <span slot="hint-text">
                    {"Please select date of birth from calender"}
                  </span>
                ) : undefined}
              </OmdsDatePickerField>
              <OmFormInputFieldWrapper
                label="Mobile number"
                id="MobileNumber"
                name="MobileNumber"
                maxLength={13}
                // prefixString="+233"
                labelAlwaysShow={true}
                isSensitive
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. +233 54 891 2451"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={beneficiary?.MobileNumber}
                state={isInputValid(beneficiary?.MobileNumber)}
                errorMessage={
                  isInputValid(beneficiary?.MobileNumber) === "error"
                    ? "Please enter mobile number."
                    : undefined
                }
                hintText= 'If Beneficiary is a Minor please provide the Contact Number of Person with Legal Primary Custody and caregiver of the minor'
                onPersist={(e) => handleChange(e)}
              />
            </div>

            <div className="action-buttons">
              <OmButton
                className="cancel-button"
                type="text"
                size="small"
                text="CANCEL"
                onClick={() => cancelAndCloseEditing()}
              />

              <OmButton
              disabled={!areAllInputsInvalid(beneficiary)}
                className="save-button"
                type="primary"
                size="small"
                text="SAVE BENEFICIARY"
                onClick={() => saveBeneficiary()}
              />
            </div>
          </div>
        </OmApplicationBeneficiaryDetails>
      </div>
    </OmSideDrawer>
  );
};

export default EditBeneficiariesDetailsSideDrawer;
