import { AGENTS } from '../../applications/digital-funeral/constants/refeferenceData/agents';
import useLocalStorage from '../../applications/digital-funeral/hooks/useLocalStorage';
import { INPUT_STATE } from '../../configs/digital-funeral-configs/config';

export const isBeneficiaryEntryNew = (id, arr) => {
    return arr.some((obj) => obj.id !== id);
};

export const getBeneficiaryIndex = (id, arr) => {
    return arr.findIndex((obj) => obj.id === id);
};

export const isInputValid = (val) => {
    if (val === null || (val !== undefined && val.toString().trim() === '')) {
        return INPUT_STATE.ERROR;
    } else if (val === undefined || !val) {
        return INPUT_STATE.UNTOUCHED;
    }
    return INPUT_STATE.VALID;
};

export const areAllInputsInvalid = (obj) => {
    return (
        obj &&
    Object.values(obj).every((val) => isInputValid(val) === INPUT_STATE.VALID)
    );
};

export const sumPercentageAllocationValues = (array) => {
    return array.reduce((total, obj) => {
        const age = parseInt(obj?.perc_alloc);
        if (!isNaN(age)) {
            return total + age;
        }
        return total;
    }, 0);
};


export const evenlySplitPercentageAllocation = (array) => {
    const length = array.length;
    const percAlloc = Math.floor(100 / length); // Calculate the base percentage allocation
    const remainder = 100 % length; // Calculate the remainder
    // Distribute the base percentage allocation among the array objects
    const result = array.map((obj, index) => ({
        ...obj,
        perc_alloc: percAlloc + (index < remainder ? 1 : 0),
    }));
    return result;
};

export const isValidGhanaCardNumber = (input) => {
    const regex = /^GHA-\d{9}-\d$/;
    return regex.test(input);
};

export const isValidDriversLicenseNumber = (input) => {
    const regex = /^[A-Z]{3}-\d{8}-\d{5}$/;
    return regex.test(input);
};

export const isValidDriversLicenseRefNumber = (input) => {
    const regex = /^\d{8}[A-Z]\d$/;
    return regex.test(input);
};
export const isValidPassportID = (input) => {
    const regex = /^[A-Z]{1}[0-9]{7}$/;
    return regex.test(input);
};

export const isValidVoterID = (input) => {
    const regex = /^\d{10}$/;
    return regex.test(input);
};

export const isValidSsnit = (input) => {
    const regex = /^\[A-Z]\d{12}$/;
    return regex.test(input);
};


export const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

export const isUserAbove15YearsOfBirth = (dateValue) =>{
    const today = new Date();
    const userDate = new Date(dateValue);
    const ageInMillis = today - userDate
    const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
    return ageInYears >= 15;
}

export const isUserAbove70YearOfBirth = (dateValue) =>{
    const today = new Date();
    const userDate = new Date(dateValue);
    const ageInMillis = today - userDate
    const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
    return ageInYears >= 70;
}

export const getAgeValue = (dateValue) =>{
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const userDate = new Date(dateValue);
    const ageInMillis = today - userDate
    const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
    return Math.floor(ageInYears);
}

export const isDateInPast = (dateValue) => {
  
    const givenDate = new Date(dateValue)
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if(givenDate < today){
        //yes userDate is in the past
        return true;
    }else {
        // is in the future
        return false;
    }
}


export const flattenObject = (obj) => {
    const flattened = {};
    function flatten(obj) {
        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                flatten(obj[key], key);
            } else {
                flattened[key] = obj[key];
            }
        }
    }
    flatten(obj);
    return flattened;
}

export const  objectToFormData = (obj) => {
    const formData = new FormData();

    function appendFormData(data, prefix) {
        if (Array.isArray(data)) {
            data.forEach((value, index) => {
                const arrayKey = `${prefix}[${index}]`;
                appendFormData(value, arrayKey);
            });
        } else if (typeof data === 'object' && data !== null) {
            Object.entries(data).forEach(([key, value]) => {
                const nestedKey = prefix ? `${prefix}.${key}` : key;
                appendFormData(value, nestedKey);
            });
        } else {
            formData.append(prefix, data);
        }
    }
    appendFormData(obj);
    return formData;
}

export const objectArrayToFormData = (arr) => {
    const formData = new FormData();

    arr.forEach((arr, index) => {
        for (let key in arr) {
            key != 'id' && formData.append(
                `benef_data[${index}][${key}]`,
                arr[key]
            );
        }
    });

    return formData;
}

export const formatFileSize = (bytes) => {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export const Redirect = (url) => {
    return new Promise((resolve, reject) => {
        if (typeof window !== 'undefined') {
            window.location.replace(url);
            resolve();
        } else {
            reject(new Error('Window object is not available..'));
        }
    });
};

export const getSLAMSBaseURL = () => {
    //getting the base url from the browser
    return process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-dms.oldmutual.com.gh:443' : 'https://prod-dms.oldmutual.com.gh';
}

export const relationshipAcronym = (relationship) => {
    let r = null;
    if(relationship == 'Son'){
        r = 'SN';
    }else if(relationship == 'Daughter'){
        r = 'DT';
    }else if(relationship == 'Aunt'){
        r = 'AN';
    }else if(relationship == 'Brother'){
        r = 'BR';
    }else if(relationship == 'Cousin'){
        r = 'CS';
    }else if(relationship == 'Father'){
        r = 'FA';
    }else if(relationship == 'Mother'){
        r = 'MO';
    }else if(relationship == 'Sister'){
        r = 'SS';
    }else if(relationship == 'Father in-law'){
        r = 'FI';
    }else if(relationship == 'Mother in-law'){
        r = 'MI';
    }else if(relationship == 'Spouse'){
        r = 'SP';
    }else if(relationship == 'Brother in-law'){
        r = 'BRI';
    }else if(relationship == 'Sister in-law'){
        r = 'SSI';
    }else if(relationship == 'Daughter in-law'){
        r = 'DTI';
    }else if(relationship == 'Grand Daughter'){
        r = 'GD';
    }else if(relationship == 'Grand Mother'){
        r = 'GR';
    }else if(relationship == 'Nephew'){
        r = 'NP';
    }else if(relationship == 'Niece'){
        r = 'NI';
    }else if(relationship == 'Uncle'){
        r = 'UN';
    }else if(relationship == 'Wife'){
        r = 'WF';
    }else if(relationship == 'Son in-law'){
        r = 'SNI';
    }else if(relationship == 'Neighbour'){
        r = 'NB';
    }
    return r;
}

export const getEmergentPayCredentials = () => {
    return {
        return_url: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://d1b2sh1ghml15h.cloudfront.net/ekyire-asem/' : 'https://www.oldmutual.com.gh/ekyire-asem/',
        payment_url: 'https://test-payment.oldmutual.com.gh/ProcessPayment', // used test because  the payment company do not  have a  live env as  oof now so test uses prod
        // client_id: process.env.GATSBY_DMS_CLIENT_ID,
        // client_secret: process.env.GATSBY_DMS_CLIENT_SECRET
        client_id: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? '9275f1fa-11de-4482-a8fd-fd5e0c4579d2' : '9915ea80-36e6-4ec1-8e47-5834c392dd7d',
        client_secret: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'pIMsFbwmFjWwYZF4MGZqlBBRDBlqPycGiazl6El6' : 'W4A9DvqpOSmeTqXoY0OwINWf7my0JQ7xdHeldRrR'
    }
}

export const generateString = (length) => {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result.trim();
}

export const stripSystemEmojis = (data) => {
    return data
        .replace(
            /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,'')
        .replace(/\s+/g, ' ')
        .trim();
}

export const isValidNumbers = (phone) => {
    const regex = /^([+]\d{2})?\d{9}$/;
    return regex.test(phone);
};

export const isValidName = (name) => {
    const regex = /^[a-zA-Z\s-]+$/;
    return regex.test(name);
};

export const getFuneralPremium = (coverAmt, age, hasHospitalCash=false) => {
    const sumAssured2000  = [11.51, 11.61, 11.79, 11.71, 11.83, 12.18, 13.05, 14.59, 17.20, 21.32, 27.67];
    const sumAssured3000  = [11.66, 12.0, 12.03, 11.91, 12.08, 12.70, 13.97, 16.22, 20.09, 26.36, 35.77];
    const sumAssured4000  = [11.82, 12.39, 12.33, 12.18, 12.14, 13.22, 14.82, 17.90, 23.08, 31.30, 44.08];
    const sumAssured5000  = [11.97, 12.77, 12.63, 12.44, 12.73, 13.73, 15.76, 19.48, 26.03, 36.35, 52.08];
    const sumAssured7500  = [12.32, 13.70, 13.37, 13.08, 13.51, 14.93, 18.07, 23.71, 33.34, 48.84, 72.75];
    const sumAssured10000  = [12.72, 14.67, 14.10, 13.64, 14.29, 16.22, 20.29, 27.75, 40.72, 61.39, 93.37];
    const sumAssured12500  = [13.12, 15.64, 14.76, 14.30, 15.01, 17.49, 22.62, 31.96, 48.01, 73.76, 113.90];
    const sumAssured15000  = [13.51, 16.61, 15.51, 14.93, 15.80, 18.76, 24.91, 36.13, 55.38, 86.34, 134.79];
    const sumAssured20000  = [14.28, 18.55, 16.99, 16.20, 17.36, 21.23, 29.38, 44.32, 69.88, 111.45, 175.11];

    const hospitalCashData = [3.8, 4.5, 4.9, 5.2, 5.6, 5.6, 5.7, 5.8, 6.5];

    let amount = 0;
    let _name = 'sumAssured' + `${coverAmt}`;
    let arr = [];

    if(_name == 'sumAssured2000'){
        arr = sumAssured2000;
    }else if(_name == 'sumAssured3000'){
        arr = sumAssured3000;
    }else if(_name == 'sumAssured4000'){
        arr = sumAssured4000;
    }else if(_name == 'sumAssured5000'){
        arr = sumAssured5000;
    }else if(_name == 'sumAssured7500'){
        arr = sumAssured7500;
    }else if(_name == 'sumAssured10000'){
        arr = sumAssured10000;
    }else if(_name == 'sumAssured12500'){
        arr = sumAssured12500;
    }else if(_name == 'sumAssured15000'){
        arr = sumAssured15000;
    }else if(_name == 'sumAssured20000'){
        arr = sumAssured20000;
    }

    if(age <= 14){
        amount = arr[0];
    }else if(age > 14 && age <= 17){
        amount = arr[1];
    }else if(age > 17 && age <= 25){
        amount = arr[2];
    }else if(age > 25 && age <= 30){
        amount = arr[3];
    }else if(age > 30 && age <= 35){
        amount = arr[4];
    }else if(age > 35 && age <= 40){
        amount = arr[5];
    }else if(age > 40 && age <= 45){
        amount = arr[6];
    }else if(age > 45 && age <= 50){
        amount = arr[7];
    }else if(age > 50 && age <= 55){
        amount = arr[8];
    }else if(age > 55 && age <= 60){
        amount = arr[9];
    }else if(age > 60 && age <= 65){
        amount = arr[10];
    }

    return amount.toFixed(2);
}

export const getHospitalCashValue = (age) => {
    const hospitalCashData = [3.8, 4.5, 4.9, 5.2, 5.6, 5.6, 5.7, 5.8, 6.5];
    let amount = 0;

    if(age > 17 && age <= 25){
        amount = hospitalCashData[0];
    }else if(age > 25 && age <= 30){
        amount = hospitalCashData[1];
    }else if(age > 30 && age <= 35){
        amount = hospitalCashData[2];
    }else if(age > 35 && age <= 40){
        amount = hospitalCashData[3];
    }else if(age > 40 && age <= 45){
        amount = hospitalCashData[4];
    }else if(age > 45 && age <= 50){
        amount = hospitalCashData[5];
    }else if(age > 50 && age <= 55){
        amount = hospitalCashData[6];
    }else if(age > 55 && age <= 60){
        amount = hospitalCashData[7];
    }else if(age > 60 && age <= 65){
        amount = hospitalCashData[8];
    }
    return amount;
}


export const getTotalFuneralCalculationPremium = (coverAmt, members, cashBenefit=0) => {
    let membersPremium = 0; 
    const CA = parseFloat(coverAmt);
    const CB = parseFloat(cashBenefit);
    
    members.map(m => { membersPremium += parseFloat(m?.coverAmtPremium) });
    
    return (membersPremium + CA + CB).toFixed(2);
}

export const stripZeroPhoneNumber = (phonenumber) => {
    if (phonenumber.startsWith('0'))
    {
        return phonenumber = phonenumber.substr(1);
    }
    return phonenumber;
}

export const getAgentsReferential = (data) => {
    const agents = AGENTS;

    let filteredAgent = agents.filter((_agent) => {
        return `${_agent.AgentNoCode} (${_agent.name})` === data;
    });

    return filteredAgent[0]?.id;
}

export const getIDReferential = (data) => {
    if(data === 'Ghana Card Number'){
        return 'ID';
    }else{
        return 'VT';
    }
}

export const getHubtelCredentials = () => {
    return {
        return_url: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://d1b2sh1ghml15h.cloudfront.net/ekyire-asem' : 'https://www.oldmutual.com.gh/ekyire-asem',
        payment_url: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-payintegrations.oldmutual.com.gh/api/v2/hubteldirectdebitpayment/initializev2':'https://payintegrations.oldmutual.com.gh/api/v2/hubteldirectdebitpayment/initializev2' ,
        payment_verifyOTP: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-payintegrations.oldmutual.com.gh/api/v1/hubteldirectdebitpayment/verify-otp':'https://payintegrations.oldmutual.com.gh/api/v1/hubteldirectdebitpayment/verify-otp' ,
        baseURL: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-payintegrations.oldmutual.com.gh/':'https://payintegrations.oldmutual.com.gh/' ,
        API_KEY: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'ApiKey ty2h2k64i4z7glfg00wyzzuqw7tzzk7j' : 'ApiKey E6adPETAkDZl7hT0mN1MhTx8jwqCECL0__'
    }
}

export const hubtelNetworkType = (telco='MTN') => {
    if(telco == 'MTN'){
        return 0;
    }else if(telco == 'Vodafone'){
        return 1;
    }else if(telco == 'AirtelTigo'){
        return 2;
    }
}

export const removeLocalStorage = () => {
    localStorage.removeItem('funeralData');
    localStorage.removeItem('f_activePageIndex');
    localStorage.removeItem('beneficiaryListDataFuneral');
    localStorage.removeItem('OMDF_policy_no');
    localStorage.removeItem('coverMemberList');
}

export const OM_API_BASE_URL = process.env.GATSBY_ENVIRONMENT_NAME === 'prod' ? 'https://goals-api.my.oldmutual.co.za/om-api/mvest' : 'https://goals-qa.digital.omapps.net:8080/wa/om-api/mvest';

export const pushToGAonNext = (_eventName=null, _payload=null) => {
    const index = localStorage.getItem('f_activePageIndex');

    let payload = {funeralData: index == 3 || index == 4  ? JSON.parse(localStorage.getItem('beneficiaryListDataFuneral')) : index == 1 || index == 2 ? JSON.parse(localStorage.getItem('coverMemberList')) : JSON.parse(localStorage.getItem('funeralData')), f_activePageIndex: index}


    if(index == 0){
        window.dataLayer.push({'event' : 'add_personal_info', 'response' : payload })
    }else if(index == 1 || index == 2){
        window.dataLayer.push({'event' : 'add_policy_details', 'response' : payload })
    }else if(index == 3 || index == 4){
        window.dataLayer.push({'event' : 'add_beneficiary', 'response' : payload })
    }else{
        window.dataLayer.push({'event' : _eventName, 'response' : _payload })
    }
}
