import React, { useContext } from 'react';
import { DEFAULT_VALIDATION_DELAY } from '../../../../../../ghana/configs/mvest-configs/config';
import {
    OmFormDropdownFieldWrapper,
    OmFormInputFieldWrapper,
} from '@om/component-library-react';
import { MVestContext } from '../../../context/MVestContext';
import {
    isInputValid,
    isUserAbove15YearsOfBirth,
    isValidEmail,
    isValidGhanaCardNumber,
    isValidName,
    stripSystemEmojis,
} from '../../../../../../ghana/utils/mvest-utils/util';
import { OmdsDatePickerField } from '@mom-omds/react-design-system-components/dist/generated/components';
import useIdTypeSelection from '../../../hooks/useIdTypeSelection';
const referenceAgents = require('../../../constants/refeferenceData/agents')
const referenceNationalities = require('../../../constants/refeferenceData/countries');
const referenceGender = require('../../../constants/refeferenceData/genders');
const referenceRegions = require('../../../constants/refeferenceData/ghanaRegions')
const referenceIdTypes = require('../../../constants/refeferenceData/idTypes')


const SchemeDetailsSection = () => {

    const { mVestData, updateMVestData } = useContext(MVestContext);

    const handleChange = (e) => {
        const value = e.detail;
        const input = e.target.name;
        let formattedValue = '';
        if (input === 'id_number') {
            const strippedValue = value.replace(/-/g, '');
            if (mVestData?.schemeDetails?.idType === 'Ghana Card Number') {
                formattedValue = strippedValue.replace(/^(\w{3})(\w{9})(\w)$/, '$1-$2-$3');
            } else if (mVestData?.schemeDetails?.idType === 'Driver’s license') {
                formattedValue = strippedValue.replace(/^(\w{3})(\w{8})(\w{5})$/, '$1-$2-$3');
            } else if (mVestData?.schemeDetails?.idType === 'Voter’s card') {
                formattedValue = value;
            } else if (mVestData?.schemeDetails?.idType === 'SSNIT') {
                formattedValue = value;
            } else {
                formattedValue = value;
            }
        } else if (input === 'mobile_number') {
            formattedValue = value.startsWith('+233') ? value : '+233' + value;
            if(value.length < 8){
                updateMVestData({ [input]: null }, 'schemeDetails');
                return;
            }
        }else if (input === 'first_name' || input === 'other_names') {
            if(!isValidName(value) || value.trim().length < 2 || !isNaN(value)){
                updateMVestData({ [input]: null }, 'schemeDetails');
                return;
            }
            formattedValue = stripSystemEmojis(value);
        } else {
            formattedValue = value;
        }
        updateMVestData({ [input]: formattedValue }, 'schemeDetails');
    };

    return (
        <>
            <h6 className="medium no-bottom-margin">Personal details</h6>

            <OmFormInputFieldWrapper
                label="First name"
                id="first_name"
                name="first_name"
                labelAlwaysShow={true}
                isSensitive
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. John"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={mVestData?.schemeDetails?.first_name}
                state={isInputValid(mVestData?.schemeDetails?.first_name) && isValidName(mVestData?.schemeDetails?.first_name)}
                errorMessage={
                    isInputValid(mVestData?.schemeDetails?.first_name) === 'error'
                        ? 'Please enter your first name'
                        : undefined
                }
                onPersist={(e) => handleChange(e)}
            />
            <OmFormInputFieldWrapper
                label="Last name"
                id="other_names"
                name="other_names"
                labelAlwaysShow={true}
                isSensitive
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. Doe"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={mVestData?.schemeDetails?.other_names}
                state={isInputValid(mVestData?.schemeDetails?.other_names) && isValidName(mVestData?.schemeDetails?.other_names)}
                errorMessage={
                    isInputValid(mVestData?.schemeDetails?.other_names) === 'error'
                        ? 'Please enter your last name'
                        : undefined
                }
                onPersist={(e) => handleChange(e)}
            />
    

            <OmFormDropdownFieldWrapper
                id="sex"
                name="sex"
                useNativeMobileDropdown
                className="inline-field"
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Gender"
                align="left"
                type="text"
                value={mVestData?.schemeDetails?.sex}
                state={isInputValid(mVestData?.schemeDetails?.sex)}
                errorMessage={
                    isInputValid(mVestData?.schemeDetails?.sex) === 'error'
                        ? 'Please select your gender'
                        : undefined
                }
                options={
                    referenceGender &&
          JSON.stringify(
              referenceGender.GENDERS.map((option) => ({
                  value: option.fullcode,
                  label: option.description,
              }))
          )
                }
                onOnSelect={(e) =>
                    updateMVestData({ sex: e.detail.value }, 'schemeDetails')
                }
            />

            <OmFormDropdownFieldWrapper
                id="nationality"
                name="nationality"
                useNativeMobileDropdown
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Country of birth"
                align="left"
                type="text"
                value={mVestData?.schemeDetails?.nationality}
                state={isInputValid(mVestData?.schemeDetails?.nationality)}
                errorMessage={
                    isInputValid(mVestData?.schemeDetails?.nationality) === 'error'
                        ? 'Please enter your country of birth'
                        : undefined
                }
                options={
                    referenceNationalities &&
          JSON.stringify(
              referenceNationalities.COUNTRIES.map((option) => ({
                  value: option.fullcode,
                  label: option.description,
              }))
          )
                }
                onOnSelect={(e) =>
                    updateMVestData({ nationality: e.detail.value }, 'schemeDetails')
                }
            />
            <OmdsDatePickerField
                label="Date of birth"
                value={mVestData?.schemeDetails?.birth_date}
                hasError={
                    isInputValid(mVestData?.schemeDetails?.birth_date) === 'error' || !isUserAbove15YearsOfBirth(mVestData?.schemeDetails?.birth_date)
                }
                onPersist={(e) =>
                    updateMVestData({ birth_date: e.detail }, 'schemeDetails')
                }
      
                placeholder="e.g. 1980-05-05"
            >
                {isInputValid(mVestData?.schemeDetails?.birth_date) === 'error' ? (
                    <span slot="hint-text">
                        {'Please select your date of birth from calender'}
                    </span>
                ) : ( isUserAbove15YearsOfBirth(mVestData?.schemeDetails?.birth_date) ? undefined :  
                    <span style={{color: '#e83b0c'}} slot="hint-text">
                        {'You must be at least 15 Years Of Age'}
                    </span> ) }
            </OmdsDatePickerField>
            <OmFormInputFieldWrapper
                label="Mobile number"
                id="mobile_number"
                name="mobile_number"
                maxLength={13}
                // prefixString="+233"
                labelAlwaysShow={true}
                isSensitive
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. +233 54 891 2451"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={mVestData?.schemeDetails?.mobile_number}
                state={isInputValid(mVestData?.schemeDetails?.mobile_number)}
                errorMessage={
                    isInputValid(mVestData?.schemeDetails?.mobile_number) === 'error'
                        ? 'Please enter your mobile number.'
                        : undefined
                }
                onPersist={(e) => handleChange(e)}
            />

            <OmFormInputFieldWrapper
                label="Mobile Money Number (MTN or Telecel)"
                id="momo_number"
                name="momo_number"
                maxLength={10}
                labelAlwaysShow={true}
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. 054 891 2451"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={mVestData?.schemeDetails?.momo_number}
                state={isInputValid(mVestData?.schemeDetails?.momo_number)}
                errorMessage={ isInputValid(mVestData?.schemeDetails?.momo_number) === 'error' ? 'Please enter your mobile number.' : undefined }
                onPersist={(e) => handleChange(e)}
            />

            <OmFormInputFieldWrapper
                label="Postal code / Physical address"
                id="postal_address"
                name="postal_address"
                labelAlwaysShow={true}
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                isSensitive
                placeholder="e.g. 33 Barlow Street, Accra"
                value={mVestData?.schemeDetails?.postal_address}
                state={isInputValid(mVestData?.schemeDetails?.postal_address)}
                errorMessage={
                    isInputValid(mVestData?.schemeDetails?.postal_address) === 'error'
                        ? 'Please enter your postal code or physical address'
                        : undefined
                }
                onPersist={(e) => handleChange(e)}
            />
            {mVestData?.schemeDetails?.nationality == 'Ghana' ? <OmFormDropdownFieldWrapper
                id="region"
                name="region"
                useNativeMobileDropdown
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Region"
                align="left"
                type="text"
                value={mVestData?.schemeDetails?.region}
                state={isInputValid(mVestData?.schemeDetails?.region)}
                errorMessage={
                    isInputValid(mVestData?.schemeDetails?.region) === 'error'
                        ? 'Please enter your region'
                        : undefined
                }
                options={
                    referenceRegions &&
          JSON.stringify(
              referenceRegions.REGIONS.map((option) => ({
                  value: option.fullcode,
                  label: option.description,
              }))
          )
                }
                onOnSelect={(e) =>
                    updateMVestData({ region: e.detail.value }, 'schemeDetails')
                }
            /> :
                <OmFormInputFieldWrapper
                    label="Region"
                    id="region"
                    name="region"
                    labelAlwaysShow={true}
                    required
                    formId="schemeDetailsForm"
                    size="full"
                    align="left"
                    type="text"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    isSensitive
                    placeholder="e.g. West Africa"
                    value={mVestData?.schemeDetails?.region}
                    state={isInputValid(mVestData?.schemeDetails?.region)}
                    errorMessage={
                        isInputValid(mVestData?.schemeDetails?.region) === 'error'
                            ? 'Please enter your region'
                            : undefined
                    }
                    onPersist={(e) => handleChange(e)}
                />
            }

            <OmFormDropdownFieldWrapper
                id="idType"
                name="idType"
                useNativeMobileDropdown
                className="inline-field"
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="ID Type"
                align="left"
                type="text"
                value={mVestData?.schemeDetails?.idType}
                state={isInputValid(mVestData?.schemeDetails?.idType)}
                errorMessage={
                    isInputValid(mVestData?.schemeDetails?.idType) === 'error'
                        ? 'Please select your ID Type'
                        : undefined
                }
                options={
                    referenceIdTypes &&
          JSON.stringify(
              referenceIdTypes.ID_TYPES.map((option) => ({
                  value: option.fullcode,
                  label: option.description,
              }))
          )
                }
                onOnSelect={(e) =>
                    updateMVestData({ idType: e.detail.value }, 'schemeDetails')
                }
            />

            <OmFormInputFieldWrapper
                label={useIdTypeSelection(referenceIdTypes, mVestData)?.label}
                id="id_number"
                name="id_number"
                isSensitive
                labelAlwaysShow={true}
                maxLength={useIdTypeSelection(referenceIdTypes, mVestData)?.maxLength}
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder={useIdTypeSelection(referenceIdTypes, mVestData)?.placeholder}
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={mVestData?.schemeDetails?.id_number}
                state={isInputValid(mVestData?.schemeDetails?.id_number)}
                errorMessage={useIdTypeSelection(referenceIdTypes, mVestData)?.validator() ? useIdTypeSelection(referenceIdTypes, mVestData)?.errorMessage : undefined}
                // hintText={
                //   isValidGhanaCardNumber(mVestData?.schemeDetails?.id_number) === false
                //     ? 'starts with "GHA" followed by 10 digits in the format "GHA-XXXXXXXXXX-X"'
                //     : undefined
                // }
                onPersist={(e) => handleChange(e)}
            />

            <OmFormInputFieldWrapper
                label="Email Address (Optional)"
                id="email_address"
                name="email_address"
                labelAlwaysShow={true}
                type="email"
                isSensitive
                // required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                keyboardMode="email"
                placeholder="e.g. johndoe@gmail.com "
                // validationDelay={DEFAULT_VALIDATION_DELAY}
                value={mVestData?.schemeDetails?.email_address}
                // state={isInputValid(mVestData?.schemeDetails?.email_address)}
                // errorMessage={!isValidEmail(mVestData?.schemeDetails?.email_address) ? 'Please enter a valid email address' :  undefined }
                onPersist={(e) => handleChange(e)}
            />
            <OmFormInputFieldWrapper
                label="TIN (Optional)"
                id="tin"
                name="tin"
                isSensitive
                formId="schemeDetailsForm"
                size="full"
                align="left"
                maxLength={10}
                placeholder="e.g. 293586235293"
                type="text"
                labelAlwaysShow={true}
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={mVestData?.schemeDetails?.tin}
                // state={isInputValid(mVestData?.schemeDetails?.tin)}
                // errorMessage={
                //   isInputValid(mVestData?.schemeDetails?.tin) === "error"
                //     ? "Please enter yourTax Identification Number"
                //     : undefined
                // }
                onPersist={(e) => handleChange(e)}
            />

            <OmFormDropdownFieldWrapper
                id="agentCode"
                name="agentCode"
                useNativeMobileDropdown
                className="inline-field"
                size="full"
                formId="schemeDetailsForm"
                placeholder="Agent Code (Optional)"
                align="left"
                type="text"
                value={mVestData?.schemeDetails?.agentCode}
                options={
                    referenceAgents &&
                JSON.stringify(
                    referenceAgents.AGENTS.map((option) => ({
                        value: option.name,
                        label: `${option.AgentNoCode} (${option.name})`,
                    }))
                )
                }
                onOnSelect={(e) => updateMVestData({ agentCode: e.detail.value }, 'schemeDetails') }
            />
        </>
    );
};

export default SchemeDetailsSection;
