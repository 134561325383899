module.exports.REGIONS = [
    { fullcode: "Ahafo Region (formerly part of Brong-Ahafo Region)", description: "Ahafo Region (formerly part of Brong-Ahafo Region)" },  
    { fullcode: "Ashanti Region", description: "Ashanti Region" },
    { fullcode: "Bono East Region (formerly part of Brong-Ahafo Region)", description: "Bono East Region (formerly part of Brong-Ahafo Region)" },
    { fullcode: "Bono Region (formerly part of Brong-Ahafo Region)", description: "Bono Region (formerly part of Brong-Ahafo Region)" },
    { fullcode: "Central Region", description: "Central Region" },
    { fullcode: "Eastern Region", description: "Eastern Region" },
    { fullcode: "Greater Accra Region", description: "Greater Accra Region" },
    { fullcode: "Northern Region", description: "Northern Region" },
    { fullcode: "North East Region (created from Northern Region)", description: "North East Region (created from Northern Region)" },
    { fullcode: "Oti Region (created from Volta Region)", description: "Oti Region (created from Volta Region)" },
    { fullcode: "Savannah Region (created from Northern Region)", description: "Savannah Region (created from Northern Region)" },
    { fullcode: "Upper East Region", description: "Upper East Region" },
    { fullcode: "Upper West Region", description: "Upper West Region" },
    { fullcode: "Volta Region", description: "Volta Region" },
    { fullcode: "Western Region", description: "Western Region" },
    { fullcode: "Western North Region (created from Western Region)", description: "Western North Region (created from Western Region)" },
    { fullcode: "Others", description: "Others" },
  ];
  