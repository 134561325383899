import { flattenObject, objectArrayToFormData, objectToFormData, getEmergentPayCredentials, getSlamsBaseURL, OM_API_BASE_URL } from '../../../utils/mvest-utils/util';

const generateBearerToken = async (clientId, clientSecret) => {
    try {
        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/auth/tokenRequest?client_id=${clientId}&client_secret=${clientSecret}`,
            {
                method: 'POST',
                headers: {
                    // 'Access-Control-Allow-Origin': process.env.GATSBY_ENVIRONMENT_NAME === 'qa' ? '*' : 'https://www.oldmutual.com.gh'
                }
            }
        );

        const data = await response.json();
        return data?.access_token;
    } catch (error) {
        console.error('Error generating bearer token:', error);
        throw error;
    }
};


export const sendClientRegistration = async (clientData, beneficiaryListData) => {
    try {
        const clientId = getEmergentPayCredentials().client_id;
        const clientSecret = getEmergentPayCredentials().client_secret;
        // console.log("...authorizing");

        const token = await generateBearerToken(clientId, clientSecret);

        
        const clientRegistartionData = flattenObject(clientData)
       
        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/api/mvest/clientRegistration`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: objectToFormData(clientRegistartionData),
            }
        );

        const data = await response.json();
        // console.log("...registered client");
        localStorage.setItem('OMMV_policy_no', data?.member_no)
 
        const beneficiariesFormData = objectArrayToFormData(beneficiaryListData)
        beneficiariesFormData.append('member_no', data?.member_no);


        // console.log("...registering beneficiaries");

        // console.log("beneficiariesFormData", beneficiariesFormData)
    
        const res = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/api/mvest/setAllBeneficiaries`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    // 'Access-Control-Allow-Origin': process.env.GATSBY_ENVIRONMENT_NAME === 'qa' ? '*' : 'https://www.oldmutual.com.gh'
                },
                body: beneficiariesFormData,
            }
        );

        const bene_res = await res.json();
        // console.log("...registered beneficiaries");

        // console.log(bene_res);
        if(bene_res.success == false){
            alert('Error: '+bene_res.message)
        }

        return bene_res
    } catch (error) {
        console.error('Error:', error);
    }
};

export const updatePaymentStatusForQA = async (policy_no) => {

    const clientId = getEmergentPayCredentials().client_id;
    const clientSecret = getEmergentPayCredentials().client_secret;

    const token = await generateBearerToken(clientId, clientSecret);

    const response = await fetch(
        `${getSlamsBaseURL()}/oldmutual_dms_api/api/policy/updatePaymentStatus?mvest_member_no=${policy_no}`,
        {
            method: 'POST',
            headers: {Authorization: `Bearer ${token}`, }
        }
    );
    const data = await response.json();
    if(process.env.GATSBY_ENVIRONMENT_NAME == 'qa'){
        // update endpoint to STATUS = PAID
        console.log('UPDATE PAYMENT::',response);    
    }
    return data;
}

export const checkIfPolicyIsPaid = async (policy_no) => {
    try {

        const acessToken = await generateBearerToken(getEmergentPayCredentials().client_id, getEmergentPayCredentials().client_secret);

        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/api/policy/get-proposal-payment-status?mvest_member_no=${policy_no}`,
            {
                method: 'GET',
                headers: {Authorization: `Bearer ${acessToken}`, }
            }
        );

        const data = await response.json();

        if(process.env.GATSBY_ENVIRONMENT_NAME == 'qa'){
            // update endpoint to STATUS = PAID
            await updatePaymentStatusForQA(acessToken, policy_no);
        }

        return data;

    } catch (error) {
        console.error('Request Error:', error);
    }
}

// Hubspot creating a contact
export const hubspotCreateContact = async (payload) => {
    try {
  
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/create-contact?email=${payload.email}&firstname=${payload.firstname}&lastname=${payload.lastname}&phone=${payload.phone}&hubspot_owner_id=${payload.hubspot_owner_id}`,
            {
                method: 'POST',
            }
        );

        const data = await response.json();
        return data;
    
    } catch (error) {
        console.error('Error:', error);
    }
};

// Hubspot updating a contact
export const hubspotUpdateContact = async (payload, contactId) => {
    try {
  
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/update-contact/${contactId}?email=${payload.email}&firstname=${payload.firstname}&lastname=${payload.lastname}&phone=${payload.phone}&hubspot_owner_id=${payload.hubspot_owner_id}`,
            {
                method: 'PATCH',
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};

// Hubspot get a contact by Email
export const hubspotGetContact = async (email) => {
    try {
  
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/get-contact/${email}`,
            {
                method: 'GET',
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};

// Hubspot creating a deal
export const hubspotCreateDeal = async (payload) => {
    try {
  
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/create-deal?amount=${payload.amount}&closedat=${payload.closedate}&dealname=${payload.dealname}&pipeline=${payload.pipeline}&dealstage=${payload.dealstage}&hubspot_owner_id=${payload.hubspot_owner_id}&dealtype=${payload.dealtype}&plan_name=${payload.plan_name}&source_of_deduction=${payload.source_of_deduction}&proposal_policy_number=${payload.proposal_policy_number}`,
            {
                method: 'POST',
            }
        );
        const data = await response.json();
        return data;
    
    } catch (error) {
        console.error('Error:', error);
    }
};

// Hubspot updating a deal
export const hubspotUpdateDeal = async (payload, dealId) => {
    try {
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/update-deal/${dealId}`,
            {
                method: 'PATCH',
                body: JSON.stringify(payload),
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};

// Hubspot assiign a contact to a deal
export const hubspotAttachContactToDeal = async (dealId, contactId) => {
    try {
  
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/assign-contact-to-deal/${dealId}/${contactId}`,
            {
                method: 'PUT',
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};