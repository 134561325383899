import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import {
    OmApplicationWizardHeader,
    OmApplicationProgressBar,
    OmIcon,
    Om1ColLayout,
} from '@om/component-library-react';
import OmButton from '../../../../../components/forms/OmButton';
import { MVestContext } from '../mVest/context/MVestContext';
import { PAGE_INDICES, PROGRESS_BAR_STEPS } from './constants/routing';
import { removeLocalStorage } from '../../utils/mvest-utils/util';

const ApplicationWizardHeader = ({ callMeBack }) => {
    const { activePageIndex } = useContext(MVestContext);
    const closeHander = () => {
        if (typeof window !== 'undefined') {
            localStorage.clear();
        }
        navigate('/personal');
    };

    return (
        <OmApplicationWizardHeader
            helpNumber="0860 665 463"
            className="banner-image"
        >
            <div slot="wizard-title">
                <p>
                    {Object.keys(PAGE_INDICES)
                        [activePageIndex]?.toString()
                        .replace('_', ' ')
                        .toUpperCase()}
                </p>
                <h5>
                    <strong>Save and Invest</strong>
                </h5>
            </div>

            <span slot="contact-button">
                <OmButton
                    type="secondary"
                    actionType="Call Me Back"
                    size="small"
                    text="Call me back"
                    callMeBackObject={callMeBack ? JSON.stringify(callMeBack) : ''}
                />
            </span>
            <a slot="close-button" className='joureyCloseNameContainer'>
                <span className='joureyCloseName' onClick={() => closeHander()}>Close</span>
                <OmIcon
                    className="close-icon"
                    iconName="close"
                    onClick={() => closeHander()}
                /> 
            </a>

            <div slot="progress-bar">
                <Om1ColLayout className="header">
                    <OmApplicationProgressBar
                        applicationName="MVest"
                        closable
                        hideDesktop={activePageIndex === PAGE_INDICES.SUCCESS}
                        // hideMobile={activePageIndex === PAGE_INDICES.SUCCESS}
                        className="banner-image"
                        steps={PROGRESS_BAR_STEPS}
                        index={
                            activePageIndex === PAGE_INDICES.BENEFICIARIES_SPLIT
                                ? PAGE_INDICES.BENEFICIARIES
                                : activePageIndex
                        }
                    >
                        <OmButton
                            actionType="url"
                            slot="close-application"
                            type="text"
                            size="small"
                            // url="/"
                            onClick={() => closeHander()}
                            text="CLOSE"
                        />
                    </OmApplicationProgressBar>
                </Om1ColLayout>
            </div>
        </OmApplicationWizardHeader>
    );
};

export default ApplicationWizardHeader;
