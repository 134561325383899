import React from 'react';
import './SchemeDetailsPage.scss';
import SchemeDetailsSection from './sections/SchemeDetailsSections';

const SchemeDetailsPage = () => {

    return (
        <div className="mvest-scheme-details-page" id='mvest_about_you'>
            <div className="mvest-scheme-details-page-wrapper">
                <h5 className="heading medium" id="heading-anchor">
                    Tell us more about yourself
                </h5>
                <p>We need the following information to set up your contract.</p>
                <SchemeDetailsSection />
            </div>
        </div>
    );
};

export default SchemeDetailsPage;

