import { INPUT_STATE } from '../../configs/mvest-configs/config';
import { AGENTS } from '../../applications/mVest/constants/refeferenceData/agents';

export const isBeneficiaryEntryNew = (id, arr) => {
    return arr.some((obj) => obj.id !== id);
};

export const getBeneficiaryIndex = (id, arr) => {
    return arr.findIndex((obj) => obj.id === id);
};

export const isInputValid = (val) => {
    if (val === null || (val !== undefined && val.toString().trim() === '')) {
        return INPUT_STATE.ERROR;
    } else if (val === undefined || !val) {
        return INPUT_STATE.UNTOUCHED;
    }
    return INPUT_STATE.VALID;
};

export const areAllInputsInvalid = (obj) => {
    return (
        obj &&
    Object.values(obj).every((val) => isInputValid(val) === INPUT_STATE.VALID)
    );
};

export const sumPercentageAllocationValues = (array) => {
    return array.reduce((total, obj) => {
        const age = parseInt(obj?.perc_alloc);
        if (!isNaN(age)) {
            return total + age;
        }
        return total;
    }, 0);
};


export const evenlySplitPercentageAllocation = (array) => {
    const length = array.length;
    const percAlloc = Math.floor(100 / length); // Calculate the base percentage allocation
    const remainder = 100 % length; // Calculate the remainder
    // Distribute the base percentage allocation among the array objects
    const result = array.map((obj, index) => ({
        ...obj,
        perc_alloc: percAlloc + (index < remainder ? 1 : 0),
    }));
    return result;
};

export const isValidGhanaCardNumber = (input) => {
    const regex = /^GHA-\d{9}-\d$/;
    return regex.test(input);
};

export const isValidDriversLicenseNumber = (input) => {
    const regex = /^[A-Z]{3}-\d{8}-\d{5}$/;
    return regex.test(input);
};

export const isValidDriversLicenseRefNumber = (input) => {
    const regex = /^\d{8}[A-Z]\d$/;
    return regex.test(input);
};
export const isValidPassportID = (input) => {
    const regex = /^[A-Z]{1}[0-9]{7}$/;
    return regex.test(input);
};

export const isValidVoterID = (input) => {
    const regex = /^\d{10}$/;
    return regex.test(input);
};

export const isValidSsnit = (input) => {
    const regex = /^\[A-Z]\d{12}$/;
    return regex.test(input);
};


export const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

export const isUserAbove15YearsOfBirth = (dateValue) =>{
    const today = new Date();
    const userDate = new Date(dateValue);
    const ageInMillis = today - userDate
    const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
    return ageInYears >= 15;
}


export const flattenObject = (obj) => {
    const flattened = {};
    function flatten(obj) {
        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                flatten(obj[key], key);
            } else {
                flattened[key] = obj[key];
            }
        }
    }
    flatten(obj);
    return flattened;
}


export const  objectToFormData = (obj) => {
    const formData = new FormData();

    function appendFormData(data, prefix) {
        if (Array.isArray(data)) {
            data.forEach((value, index) => {
                const arrayKey = `${prefix}[${index}]`;
                appendFormData(value, arrayKey);
            });
        } else if (typeof data === 'object' && data !== null) {
            Object.entries(data).forEach(([key, value]) => {
                const nestedKey = prefix ? `${prefix}.${key}` : key;
                appendFormData(value, nestedKey);
            });
        } else {
            formData.append(prefix, data);
        }
    }

    appendFormData(obj);
    return formData;
}


export const objectArrayToFormData = (arr) => {
    const formData = new FormData();

    arr.forEach((arr, index) => {
        for (let key in arr) {
            key != 'id' && formData.append(
                `benef_data[${index}][${key}]`,
                arr[key]
            );
        }
    });

    return formData;
}



export const formatFileSize = (bytes) => {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}


export const Redirect = (url) => {
    return new Promise((resolve, reject) => {
        if (typeof window !== 'undefined') {
            window.location.replace(url);
            resolve();
        } else {
            reject(new Error('Window object is not available.'));
        }
    });
};



export function base64ToBlob(base64String, mimeType, filename){
    const base64WithoutPrefix = base64String.replace(/^data:[^;]+;base64,/, '');
    const arrayBuffer = new Uint8Array(atob(base64WithoutPrefix).split('').map(char => char.charCodeAt(0)));
    const blob = new Blob([arrayBuffer], { type: mimeType, name: filename });
    const options = { type: mimeType || blob.type };
    return new File([blob], filename, options);
}

// export const base64ToBlob = (str, filename) => {
//    // Check if the 'str' parameter is undefined or null
//    if (!str) {
//     throw new Error('Base64 string is undefined or null.');
//   }

//   // Extract content type and base64 payload from the original string
//   const pos = str?.indexOf(';base64,');
//   const type = str?.substring(5, pos);
//   const b64 = str?.substr(pos + 8);
//   // Decode base64
//   const imageContent = atob(b64);
//   // Create an ArrayBuffer and a view (as unsigned 8-bit)
//   const buffer = new ArrayBuffer(imageContent?.length);
//   const view = new Uint8Array(buffer);
//   // Fill the view using the decoded base64
//   for (let n = 0; n < imageContent?.length; n++) {
//     view[n] = imageContent?.charCodeAt(n);
//   }
//   // Convert ArrayBuffer to Blob
//   const blob = new Blob([buffer], { type });
//   // Set the filename for the Blob
//   blob?.name = filename;
//   return new File([blob], blob?.name, {type: blob?.type});
// };
export const getSlamsBaseURL = () => {
    //getting the base url from the browser
    return process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-dms.oldmutual.com.gh:443' : 'https://prod-dms.oldmutual.com.gh';
}

export const generateString = (length) => {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const getEmergentPayCredentials = () => {
    return {
        return_url: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://d1b2sh1ghml15h.cloudfront.net/mvest' : 'https://www.oldmutual.com.gh/mvest',
        payment_url: 'https://test-payment.oldmutual.com.gh/ProcessPayment', // used test because  the payment company do not  have a  live env as  oof now so test uses prod
        // client_id: process.env.GATSBY_DMS_CLIENT_ID,
        // client_secret: process.env.GATSBY_DMS_CLIENT_SECRET
        client_id: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? '9275f1fa-11de-4482-a8fd-fd5e0c4579d2' : '9915ea80-36e6-4ec1-8e47-5834c392dd7d',
        client_secret: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'pIMsFbwmFjWwYZF4MGZqlBBRDBlqPycGiazl6El6' : 'W4A9DvqpOSmeTqXoY0OwINWf7my0JQ7xdHeldRrR'
    }
}

export const stripSystemEmojis = (data) => {
    return data
        .replace(
            /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,'')
        .replace(/\s+/g, ' ')
        .trim();
}

export const isValidName = (name) => {
    const regex = /^[a-zA-Z\s-]+$/;
    return regex.test(name);
};

export const stripZeroPhoneNumber = (phonenumber) => {
    if (phonenumber.startsWith('0'))
    {
        return phonenumber = phonenumber.substr(1);
    }
    return phonenumber;
}

export const getHubtelCredentials = () => {
    return {
        return_url: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://d1b2sh1ghml15h.cloudfront.net/mvest' : 'https://www.oldmutual.com.gh/mvest',
        payment_url: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-payintegrations.oldmutual.com.gh/api/v2/hubteldirectdebitpayment/initializev2':'https://payintegrations.oldmutual.com.gh/api/v2/hubteldirectdebitpayment/initializev2' ,
        payment_verifyOTP: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-payintegrations.oldmutual.com.gh/api/v1/hubteldirectdebitpayment/verify-otp':'https://payintegrations.oldmutual.com.gh/api/v1/hubteldirectdebitpayment/verify-otp' ,
        baseURL: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-payintegrations.oldmutual.com.gh/':'https://payintegrations.oldmutual.com.gh/' ,
        API_KEY: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'ApiKey ty2h2k64i4z7glfg00wyzzuqw7tzzk7j' : 'ApiKey E6adPETAkDZl7hT0mN1MhTx8jwqCECL0__'
    }
}

export const hubtelNetworkType = (telco='MTN') => {
    if(telco == 'MTN'){
        return 0;
    }else if(telco == 'Vodafone'){
        return 1;
    }else if(telco == 'AirtelTigo'){
        return 2;
    }
}

export const getAgentsReferential = (data) => {

    let filteredAgent = AGENTS.filter((_agent) => {
        return `${_agent.AgentNoCode} (${_agent.name})` === data;
    });

    return filteredAgent[0]?.id;
}

export const removeLocalStorage = () => {
    localStorage.removeItem('mVestData');
    localStorage.removeItem('activePageIndex');
    localStorage.removeItem('beneficiaryListData');
    localStorage.removeItem('OMMV_policy_no');
    localStorage.removeItem('OMMV_client_id');
    localStorage.removeItem('memberIdCard');
    localStorage.removeItem('memberSignature');
    localStorage.removeItem('memberSignatureFilename');
    localStorage.removeItem('memberIdCardFilename');
}

export const getAgeValue = (dateValue) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const userDate = new Date(dateValue);
    const ageInMillis = today - userDate
    const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
    return Math.floor(ageInYears);
}

export const relationshipAcronym = (relationship) => {
    let r = null;
    if(relationship == 'Son'){
        r = 'SN';
    }else if(relationship == 'Daughter'){
        r = 'DT';
    }else if(relationship == 'Aunt'){
        r = 'AN';
    }else if(relationship == 'Brother'){
        r = 'BR';
    }else if(relationship == 'Cousin'){
        r = 'CS';
    }else if(relationship == 'Father'){
        r = 'FA';
    }else if(relationship == 'Mother'){
        r = 'MO';
    }else if(relationship == 'Sister'){
        r = 'SS';
    }else if(relationship == 'Father in-law'){
        r = 'FI';
    }else if(relationship == 'Mother in-law'){
        r = 'MI';
    }else if(relationship == 'Spouse'){
        r = 'SP';
    }else if(relationship == 'Brother in-law'){
        r = 'BRI';
    }else if(relationship == 'Sister in-law'){
        r = 'SSI';
    }else if(relationship == 'Daughter in-law'){
        r = 'DTI';
    }else if(relationship == 'Grand Daughter'){
        r = 'GD';
    }else if(relationship == 'Grand Mother'){
        r = 'GR';
    }else if(relationship == 'Nephew'){
        r = 'NP';
    }else if(relationship == 'Niece'){
        r = 'NI';
    }else if(relationship == 'Uncle'){
        r = 'UN';
    }else if(relationship == 'Wife'){
        r = 'WF';
    }else if(relationship == 'Son in-law'){
        r = 'SNI';
    }else if(relationship == 'Neighbour'){
        r = 'NB';
    }
    return r;
}

export const OM_API_BASE_URL = process.env.GATSBY_ENVIRONMENT_NAME === 'prod' ? 'https://goals-api.my.oldmutual.co.za/om-api/mvest' : 'https://goals-qa.digital.omapps.net:8080/wa/om-api/mvest';

export const pushToGAonNext = (_eventName=null, _payload=null) => {
    const index = localStorage.getItem('activePageIndex');

    let payload = {mVestData: index == 2 || index == 3 ? JSON.parse(localStorage.getItem('beneficiaryListData')) : JSON.parse(localStorage.getItem('mVestData')), activePageIndex: index}

    if(index == 0){
        window.dataLayer.push({'event' : 'add_personal_info', 'response' : payload })
    }else if(index == 1){
        window.dataLayer.push({'event' : 'add_contribution', 'response' : payload })
    }else if(index == 2 || index == 3){
        window.dataLayer.push({'event' : 'add_beneficiary', 'response' : payload })
    }else{
        window.dataLayer.push({'event' : _eventName, 'response' : _payload })
    }
}
