import { isValidDriversLicenseNumber, isValidDriversLicenseRefNumber, isValidGhanaCardNumber, isValidPassportID, isValidSsnit, isValidVoterID } from '../../../utils/mvest-utils/util';

const useIdTypeSelection = (referenceIdTypes, mVestData) => {
    const getIdTypeSelection = () => {
        const index = referenceIdTypes.ID_TYPES.findIndex(
            (item) => item.fullcode === mVestData?.schemeDetails?.idType
        );

        switch (index) {
        case 0:
            return {
                label: 'Ghana card number',
                value: 'Ghana Card Number',
                maxLength: 15,
                validator: () => !isValidGhanaCardNumber(mVestData?.schemeDetails?.id_number),
                errorMessage: "Ghana card number should start with 'GHA' followed by 9 digits, and 1 digit at the end e.g:'GHA-XXXXXXXXXX-X'",
                placeholder: 'e.g. GHA-123456789-0',
            };
        case 1:
            return {
                label: 'Voter’s card',
                value: 'Voter’s card',
                maxLength: 10,
                validator: () => !isValidVoterID(mVestData?.schemeDetails?.id_number),
                errorMessage: 'Please enter your Voter’s Card Number; includes 10 digits',
                placeholder: 'e.g. 1234567890',
            };
      
        case 2:
            return {
                label: 'Passport',
                value: 'Passport',
                maxLength: 8,
                validator: () => !isValidPassportID(mVestData?.schemeDetails?.id_number),
                errorMessage: 'Please enter your Passport ID; includes 1 uppercase letter followed by 7 digits',
                placeholder: 'e.g. Z0123456',
            };
        case 3:
            return {
                label: 'Driver’s license',
                value: 'Driver’s license',
                maxLength: 10,
                validator: () => !isValidDriversLicenseRefNumber(mVestData?.schemeDetails?.id_number),
                errorMessage: 'Please enter your Driver’s license number; includes 8 digits followed by an uppercase letter and 1 digits',
                placeholder: 'e.g. ABC-12345678-12345',
            };
        case 4:
            return {
                label: 'SSNIT',
                value: 'SSNIT',
                maxLength: 13,
                validator: () => !isValidSsnit(mVestData?.schemeDetails?.id_number),
                errorMessage: 'Please enter your SSNIT; includes 1 uppercase letter followed by 12 digits',
                placeholder: 'e.g. A123456789012',
            };
        case 5:
            return {
                label: 'Other',
                value: 'Other',
                maxLength: 255,
                validator: () => false,
                errorMessage: 'Please enter your Id Number',
                placeholder: 'Enter other ID type',
            };
        default:
            return {
                label: 'Ghana card number',
                value: 'Ghana Card Number',
                maxLength: 15,
                validator: () => !isValidGhanaCardNumber(mVestData?.schemeDetails?.id_number),
                errorMessage: "Ghana card number should start with 'GHA' followed by 9 digits, and 1 digit at the end e.g: 'GHA-XXXXXXXXXX-X'",
                placeholder: 'e.g. GHA-123456789-0',
            };
        }
    };

    const idTypeSelection = getIdTypeSelection();

    return{
        ...idTypeSelection,
        label: idTypeSelection.label || 'Ghana card number',
        value: idTypeSelection.value || 'Ghana Card Number',
        maxLength: idTypeSelection.maxLength || 15,
        validator: idTypeSelection.validator || (() => !isValidGhanaCardNumber(mVestData?.schemeDetails?.id_number)),
        errorMessage: idTypeSelection.errorMessage || "Please enter your Ghana card number; starts with 'GHA' followed by 9 digits, and 1 digit at the end e.g:  'GHA-XXXXXXXXXX-X'",
        placeholder: idTypeSelection.placeholder || 'e.g. GHA-123456789-0',
    };
};

export default useIdTypeSelection;
