import React, { useContext } from "react";
import "./BeneficiariesSplitPage.scss";
import ApplicationBeneficiarySplitList from "./ApplicationBeneficiarySplitList";
import { MVestContext } from "../../context/MVestContext";

const BeneficiariesSplitPage = () => {
  const { beneficiaryListData } = useContext(MVestContext);
  return (
    <div className="mvest-beneficiaries-split">
      <div className="mvest-beneficiaries-split-wrapper">
        <h5 className="no-top-margin">
          Split your investment <strong>between your beneficiaries</strong>
        </h5>

        <ApplicationBeneficiarySplitList
          beneficiaryList={beneficiaryListData}
        />
      </div>
    </div>
  );
};

export default BeneficiariesSplitPage;
