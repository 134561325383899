module.exports.ID_TYPES = [
    { fullcode: 'Ghana Card Number', description: 'Ghana Card Number' },
    {
        fullcode: 'Voter’s card',
        description: 'Voter’s card',
    },
    // {
    //   fullcode: "Passport",
    //   description: "Passport",
    // },
    // { fullcode: "Driver’s license", description: "Driver’s license" },
    // {
    //   fullcode: "SSNIT",
    //   description: "SSNIT",
    // },
    // {
    //     fullcode: 'Other',
    //     description: 'Other',
    // },
]
