import React, { useContext, useEffect, useState } from 'react';
import OmButton from '../../../../../components/forms/OmButton';
import { OmApplicationWizardFooter } from '@om/component-library-react';
import { MVestContext } from './context/MVestContext';
import { PAGE_INDICES } from './constants/routing';
import { Redirect, areAllInputsInvalid, getAgeValue, getAgentsReferential, isValidEmail, sumPercentageAllocationValues } from '../../utils/mvest-utils/util';
import { checkIfPolicyIsPaid, hubspotAttachContactToDeal, hubspotCreateContact, hubspotCreateDeal, hubspotGetContact, hubspotUpdateContact, sendClientRegistration, updatePaymentStatusForQA } from './server/api';
import { consentToRecurringPayment, initiatePayment} from './server/pay';
import {uploadMultipleFilesToS3 } from './server/aws_sdk_s3_file_upload';
import useUrlParams from './hooks/useUrlParams';
import { oneMB } from '../../configs/mvest-configs/config';
import { base64ToBlob } from '../../utils/mvest-utils/util';
import { generateString, pushToGAonNext } from '../../utils/digital-funeral-utils/util';

const ApplicationWizardFooter = () => {
    const urlParams = useUrlParams();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const {
        activePageIndex,
        hasAcceptedTermsAndCondition,
        navigateContinue,
        navigatePrevious,
        mVestData,
        beneficiaryListData,
        memberIdCard,
        memberSignature,
        memberSignatureFilename,
        memberIdCardFilename, 
        togglePaymentModal,
        updateMVestData

    } = useContext(MVestContext);
    let continueText =
    activePageIndex === PAGE_INDICES.REVIEW_APPLICATION ? (urlParams?.status_code == 1 ? (isSubmitting ? 'PROCESSING...' : 'MAKE PAYMENT') : 'SUBMIT & PAY') : ( beneficiaryListData.length >= 1 && activePageIndex === PAGE_INDICES.BENEFICIARIES ? 'CONTINUE' : 'CONTINUE');
    const isLastPage = activePageIndex === PAGE_INDICES.SUCCESS;

    useEffect(() => {
        if(activePageIndex === PAGE_INDICES.REVIEW_APPLICATION){
            checkPolicyStatus();
        }    
    },[activePageIndex])

    const checkPolicyStatus = async () => {
        if(localStorage.getItem('OMMV_policy_no')){
            setIsSubmitting(true)
            if(process.env.GATSBY_ENVIRONMENT_NAME == 'qa'){
                await updatePaymentStatusForQA(localStorage.getItem('OMMV_policy_no'))
                navigateContinue()
            }else{
                setIsSubmitting(true)
                const resp = await checkIfPolicyIsPaid(localStorage.getItem('OMMV_policy_no'));

                if(resp?.success == true){
                    navigateContinue()
                }else{
                    const queryString = window.location.search;
                    const UP = new URLSearchParams(queryString);
            
                    if(UP.get('status_code') == 1){
                        const updateResp = await updatePaymentStatusForQA(localStorage.getItem('OMMV_policy_no'))
                        if(updateResp?.success == true){
                            navigateContinue()
                        }else{
                            setIsSubmitting(false)
                        }
                    }  
                }
            }
            setIsSubmitting(false)
        }
    }
  
    const dataToCheck =()=>{
        return {
            first_name : mVestData?.schemeDetails.first_name,
            other_names : mVestData?.schemeDetails.other_names,
            birth_date : mVestData?.schemeDetails.birth_date,
            nationality : mVestData?.schemeDetails.nationality,
            mobile_number : mVestData?.schemeDetails.mobile_number,
            postal_address : mVestData?.schemeDetails.postal_address,
            region : mVestData?.schemeDetails.region,
            id_number : mVestData?.schemeDetails.id_number,
        };
    }
  
    const validateFormNextStep = () => {
        if (activePageIndex === PAGE_INDICES.SCHEME_DETAILS) {
            //&& isValidEmail(mVestData?.schemeDetails?.email_address)
            return areAllInputsInvalid(dataToCheck());
        } else if (activePageIndex === PAGE_INDICES.CONTRIBUTION_DETAILS) {
            return areAllInputsInvalid(mVestData?.contributionDetails)
        } else if (activePageIndex === PAGE_INDICES.BENEFICIARIES) {
            for (let idx = 0; idx < beneficiaryListData.length; idx++) {
                const beneficiary = beneficiaryListData[idx];
                return areAllInputsInvalid(beneficiary)
            }
        } else if (activePageIndex === PAGE_INDICES.BENEFICIARIES_SPLIT) {
            for (let idx = 0; idx < beneficiaryListData.length; idx++) {
                const beneficiary = beneficiaryListData[idx];
                return areAllInputsInvalid(beneficiary) && sumPercentageAllocationValues(beneficiaryListData) == 100
            }
        } else if (activePageIndex === PAGE_INDICES.REVIEW_APPLICATION) {
            return hasAcceptedTermsAndCondition && memberIdCard && memberSignature && memberIdCard?.size <= oneMB && memberSignature?.size <= oneMB;
        }
        return;
    };

    const pay = () => togglePaymentModal()

    const submit = async ()=>{
        setIsSubmitting(true)
        try {
            if(urlParams?.status_code == 3 || urlParams?.status_code == 2){
                pay()
                return;
            }

            //=== google analytics ===
            pushToGAonNext('begin_checkout', {currency: 'GHS', value: mVestData?.contributionDetails?.monthly_salary, items: [{ item_id: generateString(4), item_name: 'MVest' }], activePageIndex: activePageIndex})
           
            if(mVestData?.contributionDetails?.monthly_contribution === 'Monthly (MTN Users Only)'){
                // console.log("....initiating recurring payemnet")
                const consent = await consentToRecurringPayment()
                console.log('Recurring Payment::',consent);
            }
            updateMVestData({ loading: true }, 'schemeDetails')
            let payload = {
                employer_number: 'mV/2018/0739',
                age: getAgeValue(mVestData?.schemeDetails?.birth_date),
                monthly_salary: '10',
                monthly_contribution: mVestData.contributionDetails.monthly_contribution,
                first_name: mVestData?.schemeDetails?.first_name,
                other_names: mVestData?.schemeDetails?.other_names,
                birth_date: mVestData?.schemeDetails?.birth_date,
                entry_date: mVestData?.schemeDetails?.entry_date,
                nationality: mVestData?.schemeDetails?.nationality,
                mobile_number: mVestData?.schemeDetails?.mobile_number,
                postal_address: mVestData?.schemeDetails?.postal_address,
                region: mVestData?.schemeDetails?.region,
                id_number: mVestData?.schemeDetails?.id_number,
            }
            if(mVestData?.schemeDetails?.agentCode){
                payload.agent_no = getAgentsReferential(mVestData?.schemeDetails?.agentCode)
            }

            const res = await sendClientRegistration(payload, beneficiaryListData)
            if (res?.success == true ){
                const client_id = `OMPF/MV/${mVestData.schemeDetails.id_number}`
                // localStorage.setItem('OMMV_client_id',client_id)
                const memeberSignatureFile = base64ToBlob(memberSignature?.data , memberSignature?.mimeType, memberSignatureFilename);
                const memberIdCardFile = base64ToBlob(memberIdCard?.data, memberIdCard?.mimeType, memberIdCardFilename);
                const response = await uploadMultipleFilesToS3(memeberSignatureFile, memberIdCardFile, client_id );

                // ==== create deal on CRM =====
                if(process.env.GATSBY_ENVIRONMENT_NAME === 'prod'){
                    await crmDeal(mVestData?.contributionDetails?.monthly_contribution, localStorage.getItem('OMMV_policy_no'))
                }
                // ==== End of creating deal on CRM =====
                    
                updateMVestData({ loading: false }, 'schemeDetails')
                //=== google analytics ===
                pushToGAonNext('purchase', {currency: 'GHS', value: mVestData?.contributionDetails?.monthly_salary, items: [{ item_id: generateString(4), item_name: 'MVest', transaction_id: localStorage.getItem('OMMV_policy_no') }], activePageIndex: activePageIndex})
                pay()
            }else{
                updateMVestData({ loading: false }, 'schemeDetails')
                setIsSubmitting(false)
            }
        } catch (error) {
            updateMVestData({ loading: false }, 'schemeDetails')
            setIsSubmitting(false)
            console.error('Submission failed:', error);
        }
    
    }

    const crmContact = async () => {
        if(localStorage.getItem('CRM_CONTACT_ID') == null || localStorage.getItem('CRM_CONTACT_ID') == undefined){
            const _crm = await hubspotGetContact(mVestData?.schemeDetails?.email_address)

            if(!_crm.id){
                let payload = {
                    email: mVestData?.schemeDetails?.email_address,
                    firstname: mVestData?.schemeDetails?.first_name,
                    lastname: mVestData?.schemeDetails?.other_names,
                    phone: mVestData?.schemeDetails?.mobile_number,
                    hubspot_owner_id: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? '1421520828' : '1327009943'
                }
                const createCRMContact = await hubspotCreateContact(payload);
                localStorage.setItem('CRM_CONTACT_ID', createCRMContact?.id)
            }else{
                localStorage.setItem('CRM_CONTACT_ID', _crm?.id)
            }
        }else{
            let payload = {
                email: mVestData?.schemeDetails?.email_address,
                firstname: mVestData?.schemeDetails?.first_name,
                lastname: mVestData?.schemeDetails?.other_names,
                phone: mVestData?.schemeDetails?.mobile_number,
                hubspot_owner_id: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? '1421520828' : '1327009943'
            }
            await hubspotUpdateContact(payload, localStorage.getItem('CRM_CONTACT_ID'))
        }
    }

    const crmDeal = async (amount, policyNumber) => {

        const closeDate = new Date();
        closeDate.setMonth(closeDate.getMonth() + 1);

        let crmPayload = {
            amount: amount,
            closedate: closeDate.toISOString(),
            dealname: `${mVestData?.schemeDetails?.first_name} ${mVestData?.schemeDetails?.other_names} - Mvest ${policyNumber}`,
            pipeline: 'default',
            dealstage: '376751587',
            hubspot_owner_id: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? '1421520828' : '1327009943',
            dealtype: 'newbusiness',
            plan_name: 'Mvest',
            source_of_deduction: 'MoMo',
            proposal_policy_number: policyNumber,
            mobilephone: mVestData?.schemeDetails?.momo_number
        }

        const resp = await hubspotCreateDeal(crmPayload)
        // console.log('Deal Resp::', resp);

        // assign contact to deal
        if(resp?.id){
            await hubspotAttachContactToDeal(resp?.id, localStorage.getItem('CRM_CONTACT_ID'))
        } 
    }

    const nextActionButton = () => {
        if(activePageIndex === PAGE_INDICES.REVIEW_APPLICATION){
            submit()
        }else{
            pushToGAonNext()
            navigateContinue()
            if(activePageIndex === PAGE_INDICES.CONTRIBUTION_DETAILS && process.env.GATSBY_ENVIRONMENT_NAME === 'prod'){
                crmContact()
            }
        }
    }
  
    return (
        !isLastPage && (
            <OmApplicationWizardFooter
                index={activePageIndex}
                totalCount="totalSteps"
            >
                <OmButton
                    slot="left"
                    onClick={() => navigatePrevious()}
                    type="text"
                    size="small"
                    text="Previous"
                    disabled={activePageIndex <= PAGE_INDICES.SCHEME_DETAILS}
                />

                <OmButton
                    disabled={!validateFormNextStep() || isSubmitting}
                    iconName="arrow_forward"
                    slot="right"
                    onClick={() =>  nextActionButton()}
                    // onClick={() =>  activePageIndex === PAGE_INDICES.REVIEW_APPLICATION ? (urlParams?.status_code == 1 ? submit() : pay() ): navigateContinue()}
                    type="primary"
                    size="small"
                    text={continueText}
                />
            </OmApplicationWizardFooter>
        )
    );
};

export default ApplicationWizardFooter;
