import React, { useContext } from 'react';
import { DEFAULT_VALIDATION_DELAY } from '../../../../../ghana/configs/mvest-configs/config';
import { MVestContext } from '../../context/MVestContext';
import { isInputValid } from '../../../../../ghana/utils/mvest-utils/util';
import './ContributionDetailsPage.scss';
import {
    CONTRIBUTION_TYPES,
    // CONTRIBUTION_TAX_STATUS,
    // SOURCE_OF_FUNDS,
    // REDEMPTION,
} from '../../constants/refeferenceData/contributions';
import {
    OmFormDropdownFieldWrapper,
    OmFormInputFieldWrapper,
} from '@om/component-library-react';

const ContributionDetailsPage = () => {
    const { mVestData, updateMVestData } = useContext(MVestContext);
    const handleChange = (e) => {
        updateMVestData({ [e.target.name]: e.detail }, 'contributionDetails');
  
    };

    return (
        <div className="mvest-contribution-details-page" id='mvest_contribution'>
            <div className="mvest-contribution-details-page-wrapper">
                <h5 className="heading medium" id="heading-anchor">
          Now, we'd like to know your contribution details
                </h5>
                <h6 className="medium no-bottom-margin">Contribution Details</h6>

                <OmFormDropdownFieldWrapper
                    id="contribution_type"
                    name="contribution_type"
                    useNativeMobileDropdown
                    className="inline-field"
                    required
                    formId="contributionDetailsForm"
                    size="full"
                    align="left"
                    placeholder="Contribution Type"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    value={mVestData?.contributionDetails?.contribution_type}
                    state={isInputValid(
                        mVestData?.contributionDetails?.contribution_type
                    )}
                    errorMessage={
                        isInputValid(mVestData?.contributionDetails?.contribution_type) ===
            'error'
                            ? 'Please select contribution type'
                            : undefined
                    }
                    options={JSON.stringify(
                        CONTRIBUTION_TYPES.map((option) => ({
                            value: option.fullcode,
                            label: option.description,
                        }))
                    )}
                    onOnSelect={(e) =>
                        updateMVestData(
                            { contribution_type: e.detail.value },
                            'contributionDetails'
                        )
                    }
                />
                {/* 
        <OmFormDropdownFieldWrapper
          id="contributionTaxStatus"
          name="contributionTaxStatus"
          useNativeMobileDropdown
          className="inline-field"
          required
          formId="contributionDetailsForm"
          size="full"
          align="left"
          placeholder="Contribution Tax Status"
          validationDelay={DEFAULT_VALIDATION_DELAY}
          value={mVestData?.contributionTaxStatus}
          // state={{}}
          options={JSON.stringify(
            CONTRIBUTION_TAX_STATUS.map((option) => ({
              value: option.fullcode,
              label: option.description,
            }))
          )}
          //   errorMessage="Please select contribution type"
          onOnSelect={(e) =>
            updateMVestData(
              { contributionTaxStatus: e.detail.value },
              "contributionDetails"
            )
          }
        /> */}
                {/* <OmFormDropdownFieldWrapper
          id="sourceOfFunds"
          name="sourceOfFunds"
          useNativeMobileDropdown
          className="inline-field"
          required
          formId="contributionDetailsForm"
          size="full"
          align="left"
          placeholder="Source of funds"
          validationDelay={DEFAULT_VALIDATION_DELAY}
          value={mVestData?.contributionDetails?.sourceOfFunds}
          // state={{}}
          options={JSON.stringify(
            SOURCE_OF_FUNDS.map((option) => ({
              value: option.fullcode,
              label: option.description,
            }))
          )}
          //   errorMessage="Please select contribution type"
          onOnSelect={(e) =>
            updateMVestData(
              { sourceOfFunds: e.detail.value },
              "contributionDetails"
            )
          }
        /> */}

                {/* {mVestData?.contributionDetails?.sourceOfFunds === "Other" && (
          <OmFormInputFieldWrapper
            id="sourceOfFunds"
            name="sourceOfFunds"
            isSensitive
            required
            formId="contributionDetailsForm"
            size="full"
            align="left"
            placeholder="Source of funds"
            validationDelay={DEFAULT_VALIDATION_DELAY}
            value={mVestData?.contributionDetails?.sourceOfFunds}
            // state={amount.status}
            // errorMessage="Please enter your amount"
            onPersist={(e) =>
              updateMVestData(
                { sourceOfFunds: e.detail },
                "contributionDetails"
              )
            }
          />
        )} */}

                {/* <OmFormInputFieldWrapper
                    label="Monthly Salary"
                    id="monthly_salary"
                    name="monthly_salary"
                    isSensitive
                    required
                    labelAlwaysShow={true}
                    formId="contributionDetailsForm"
                    size="full"
                    align="left"
                    placeholder="e.g. 300000"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    value={mVestData?.contributionDetails?.monthly_salary}
                    state={isInputValid(mVestData?.contributionDetails?.monthly_salary)}
                    errorMessage={
                        isInputValid(mVestData?.contributionDetails?.monthly_salary) === 'error'
                            ? 'Please enter your amount.'
                            : undefined
                    }
                    onPersist={(e) => handleChange(e)}  
                /> */}

                <OmFormInputFieldWrapper
                    label={`${mVestData?.contributionDetails?.contribution_type == 'Monthly (MTN Users Only)' ? 'Monthly ' : '' } Contribution (Amount)`}
                    id="monthly_contribution"
                    name="monthly_contribution"
                    isSensitive
                    required
                    labelAlwaysShow={true}
                    formId="contributionDetailsForm"
                    size="full"
                    align="left"
                    placeholder="e.g. 5000"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    value={mVestData?.contributionDetails?.monthly_contribution}
                    state={isInputValid(mVestData?.contributionDetails?.monthly_contribution)}
                    errorMessage={
                        isInputValid(mVestData?.contributionDetails?.monthly_contribution) === 'error'
                            ? 'Please enter your amount.'
                            : undefined
                    }
                    onPersist={(e) => handleChange(e)}  
                />
                {/* <OmFormDropdownFieldWrapper
          id="redemption"
          name="redemption"
          useNativeMobileDropdown
          className="inline-field"
          required
          formId="contributionDetailsForm"
          size="full"
          align="left"
          placeholder="Redemption"
          validationDelay={DEFAULT_VALIDATION_DELAY}
          value={mVestData?.contributionDetails?.redemption}
          // state={{}}
          options={JSON.stringify(
            REDEMPTION.map((option) => ({
              value: option.fullcode,
              label: option.description,
            }))
          )}
          //   errorMessage="Please select contribution type"
          onOnSelect={(e) =>
            updateMVestData(
              { redemption: e.detail.value },
              "contributionDetails"
            )
          }
        /> */}
                <br />
            </div>
        </div>
    );
};

export default ContributionDetailsPage;
