import React, { useContext } from 'react';
import './successPage.scss';
import { navigate } from '@reach/router';
import {
    OmApplicationThankYou,
    OmApplicationThankYouPage,
    OmThreeColumnIllustration,
    OmApplicationButton
  
} from '@om/component-library-react';
import checkIcon from '../../assets/images/check.svg';
import phoneIcon from '../../assets/images/phone_circle.svg';
import fileIcon from '../../assets/images/file_upload.svg';
import docsIcon from '../../assets/images/docs.svg';

import { MVestContext } from '../../context/MVestContext';
import OmButton from '../../../../../../../components/forms/OmButton';

const SuccessPage = () => {

    const { mVestData } = useContext(MVestContext);

    const closeHander = () => {
        let check  = confirm('Are you sure you want to close this page?')
        if(check){
            if (typeof window !== 'undefined') {
                localStorage.clear();
            }
            navigate('/personal');
        }
    };

    return (
        <div className="mvest-thank-you-page">
            <OmApplicationThankYouPage>
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <div  style={{ paddingTop: '2rem'}}>
                        <OmApplicationThankYou
      
                            // slot="thank-you"
                            imageLink={checkIcon}
                            personName={`${mVestData?.schemeDetails?.first_name} ${mVestData?.schemeDetails?.other_names}`}
                            thankYouCaption={
                                'Thank you for choosing the Old Mutual MVest. \n' +
                '\n' +
                'We’ve emailed you a summary as confirmation of \n your application.'
                            }
                            // referenceNumber={`OMPF-MV-${mVestData?.schemeDetails?.id_number}`}
                            referenceNumber={`${localStorage.getItem('OMMV_policy_no')}`}
                            contactCaption={
                                'If you don’t receive the email, <br /> \n' +
                'please contact us on <strong>0303 968 667</strong>'
                            }
                            // buttonName={""}
                            // buttonLink={""}
                        />
                    </div>
                    <div style={{ marginTop: '-8rem'}}>
                        <OmThreeColumnIllustration
                            // hasBoundary={false}
                            // buttonType="text"

                            imageUrlLeft={phoneIcon}
                            iconNameLeft={'phone_icon'}
                            redirectUrlLeft={'/'}
                            redirectUrlLeftAndroid={'/'}
                            redirectUrlLeftApple={'/'}
                            subTitleLeft={'Call from your consultant'}
                            paragraphLeft={'Your dedicated consutant will give you a call'}
                            // buttonTextLeft={button_text_left}
                            // buttonActionTypeLeft={button_action_type_left}
                            // buttonUrlLeft={button_url_left}

                            imageUrlMiddle={fileIcon}
                            iconNameMiddle={'file_icon'}
                            redirectUrlMiddle={'/'}
                            redirectUrlMiddleAndroid={'/'}
                            redirectUrlMiddleApple={'/'}
                            subTitleMiddle={'Email your documnets'}
                            paragraphMiddle={
                                'Send your e-application to us with all documents to info@oldmutual.com'
                            }
                            // buttonTextMiddle={button_text_middle}
                            // buttonActionTypeMiddle={button_action_type_middle}
                            // buttonUrlMiddle={button_url_middle}

                            imageUrlRight={docsIcon}
                            iconNameRight={'docs_icon'}
                            redirectUrlRight={'/'}
                            redirectUrlRightAndroid={'/'}
                            redirectUrlRightApple={'/'}
                            subTitleRight={'Track your application'}
                            paragraphRight={
                                'See the progress of your home loan application form online'
                            }
                            // buttonTextRight={button_text_right}
                            // buttonActionTypeRight={button_action_type_right}
                            // buttonUrlRight={button_url_right}

                            buttonTexts={'TRACK APPLICATION'}
                            buttonUrl={''}
                            buttonActionType={'Call Me Back'}
                            buttonCallMeBackProduct={''}
                            buttonCallMeBackEmail={''}
                            openTab={true}
                        >
                            <h1
                                slot="section-heading"
                                dangerouslySetInnerHTML={{ __html: 'What’s next?' }}
                            />
                            <span
                                slot="section-caption"
                                dangerouslySetInnerHTML={{
                                    __html: `Keep tabs on your policy via any of the channels below. Click each icon for more info.`,
                                }}
                            />
                        </OmThreeColumnIllustration>
                    </div>
                    {/* <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "2rem", marginTop: "4rem" }}>
            <div>
              <OmApplicationButton
                text="TRACK APPLICATION"
              />
            </div>
            <div>
        
           <OmButton
          iconName="arrow_forward"
          slot="right"
          // onClick={}
          text="DONE"
          type="primary"
          size="small"
        />
            </div>
      
  
          </div> */}
                </div>
                <OmButton
                    slot="right"
                    onClick={closeHander}
                    text="Close Application"
                    type="primary"
                    size="small"
                />
            </OmApplicationThankYouPage>
        </div>
    );
};

export default SuccessPage;
