import React, { useContext } from 'react';
import './BeneficiariesPage.scss';
import { OmApplicationButton } from '@om/component-library-react';
import BeneficiaryDetailsSideDrawer from './BeneficiaryDetailsSideDrawer';
import { MVestContext } from '../../context/MVestContext';
import { ADDED_MAX_BENEFICIARIES } from '../../../../configs/mvest-configs/config';
import ApplicationBeneficiaryDetailsList from './BeneficiaryDetailsList';
import RemoveBeneficiaryModal from './RemoveBeneficiaryModal';
import MaxBeneficiariesAddedModal from './MaxBeneficiariesAddedModal';
import EditBeneficiariesDetailsSideDrawer from './EditBeneficiaryDetailsSideDrawer';

const BeneficiariesLanding = () => {
    const { toggleDrawer, setBeneficiaryId } = useContext(MVestContext);

    const clickHandler = () => {
        setBeneficiaryId();
        toggleDrawer();
    };

    return (
        <div>
            <h5>
                <strong>Why you should add a beneficiary</strong>
            </h5>
            <ul>
                <li>
                    <h6>
            As the scheme member, you will receive the payouts should anything
            happen to the people you've covered
                    </h6>
                </li>

                <li>
                    <h6>
            You can nominate someone to receive the payout in the event of your
            death.
                    </h6>
                </li>
            </ul>

            <OmApplicationButton
                text="ADD BENEFICIARY"
                iconName="add"
                onButtonClicked={() => clickHandler()}
            />
        </div>
    );
};

const BeneficiariesDetailsList = () => {
    const {
        toggleDrawer,
        beneficiaryListData,
        toggleMaxBeneficiariesModal,
        beneficiaryId,
    } = useContext(MVestContext);

    const addedMaxBeneficiaries = () => {
        return beneficiaryListData.length == ADDED_MAX_BENEFICIARIES;
    };

    const addBeneficiaryToList = () => {
        if (addedMaxBeneficiaries()) {
            toggleMaxBeneficiariesModal();
        } else {
            toggleDrawer();
        }
    };
    return (
        <div>
            <h5 className="beneficiaries-heading no-top-margin">
        Your beneficiary list
            </h5>
            <p className="name-heading small">Beneficiary name</p>
            <ApplicationBeneficiaryDetailsList
                beneficiaryList={beneficiaryListData}
            />
            {beneficiaryListData.length == ADDED_MAX_BENEFICIARIES ? '' :
                <OmApplicationButton
                    iconName="add"
                    disabled={addedMaxBeneficiaries()}
                    text="ADD BENEFICIARY"
                    onButtonClicked={() => addBeneficiaryToList()}
                    actionType={addedMaxBeneficiaries()}
                />  }
        </div>
    );
};

const BeneficiariesPage = () => {
    const { beneficiaryListData, isEditDrawerOpen } = useContext(MVestContext);
    return (
        <div className="mvest-beneficiaries" id='mvest_beneficiary'>
            <div className="mvest-beneficiaries-wrapper">
                {beneficiaryListData.length === 0 ? (
                    <BeneficiariesLanding />
                ) : (
                    <BeneficiariesDetailsList />
                )}
                <BeneficiaryDetailsSideDrawer />
                {isEditDrawerOpen && <EditBeneficiariesDetailsSideDrawer />}
                <RemoveBeneficiaryModal />
                <MaxBeneficiariesAddedModal />
            </div>
        </div>
    );
};

export default BeneficiariesPage;
