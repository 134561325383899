import React, { useContext, useRef } from 'react';
import './ReviewPage.scss';
import { MVestContext } from '../../context/MVestContext';
import { PAGE_INDICES } from '../../constants/routing';
import {
    OmApplicationReviewSection,
    OmLabeledValueSummary,
    OmCheckBox,
    OmFormError,
    OmApplicationSummaryDetailsCard,
    OmFormFileInputField,
    OmIcon,
    OmModalLoader
} from '@om/component-library-react';
import {
    OmdsCell,
    OmdsGrid,
    OmdsFileUploadFile, OmdsIcon
} from '@mom-omds/react-design-system-components/dist/generated/components';
import { formatFileSize } from '../../../../../ghana/utils/mvest-utils/util';
import file_icon_jpeg from '../../assets/icons/File_Icon_JPEG.svg'
import file_icon_jpg from '../../assets/icons/File_Icon_JPG.svg'
import file_icon_pdf from '../../assets/icons/File_Icon_PDF.svg'
import file_icon_png from '../../assets/icons/File_Icon_PNG.svg'
import file_icon_tif from '../../assets/icons/File_Icon_TIF.svg'
import file_icon_tiff from '../../assets/icons/File_Icon_TIFF.svg'
import { oneMB } from '../../../../../ghana/configs/mvest-configs/config';
import SelectTelco from '../modal/SelectTelco';

const ReviewPage = () => {
    const {
        navigateTo,
        mVestData,
        beneficiaryListData,
        hasAcceptedTermsAndCondition,
        acceptTermsAndCondition,
        memberIdCardFilename,
        memberIdCard,
        memberSignatureFilename,
        memberSignature,
        handleFileInput,
        removeFile,
        togglePaymentModal,
        paymentModal
    } = useContext(MVestContext);
    const joinName = (nameArray) => {
        return nameArray.join(' ');
    };

    const toggle = () => {
        acceptTermsAndCondition();
    };
    const memberSignatureInputFile = useRef(null);
    const memberIdCardInputFile = useRef(null)
    const fileTypeIcons = {
        jpg: file_icon_jpg,
        jpeg: file_icon_jpeg,
        pdf: file_icon_pdf,
        tif: file_icon_tif,
        tiff: file_icon_tiff,
        png: file_icon_png
    };

    const getFileStatusText = file => {
        if (file) {
            return formatFileSize(file?.size);
        }
        return `Max 1MB`
    }


    return (
        <div id='mvest_confirmation'>
            <OmdsGrid isFixed>
                <OmdsCell span="row" span-md="7">
                    <div>
                        <h4 className="page-heading sessioncamhidetext">
              Lastly, please confirm your details
                        </h4>
                        <div>
                            <OmApplicationReviewSection titleHeader="Personal Details">
                                <span
                                    slot="edit-link"
                                    onClick={() => navigateTo(PAGE_INDICES.SCHEME_DETAILS)}
                                >
                                    <h6 className="small">
                                        <strong>EDIT</strong>
                                    </h6>
                                </span>
                                <div slot="section-body">
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Full name"
                                                value={joinName([
                                                    mVestData?.schemeDetails?.first_name,
                                                    mVestData?.schemeDetails?.other_names,
                                                ])}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Date of birth"
                                                value={mVestData?.schemeDetails?.birth_date}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Gender"
                                                value={mVestData?.schemeDetails?.sex}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Country of birth"
                                                value={mVestData?.schemeDetails?.nationality}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Mobile number"
                                                value={mVestData?.schemeDetails?.mobile_number}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Region"
                                                value={mVestData?.schemeDetails?.region}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Ghana card number"
                                                value={mVestData?.schemeDetails?.id_number}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Email address"
                                                value={mVestData?.schemeDetails?.email_address}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="5">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Physical address"
                                                value={mVestData?.schemeDetails?.postal_address}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="TIN"
                                                value={mVestData?.schemeDetails?.tin}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                </div>
                            </OmApplicationReviewSection>

                            <OmApplicationReviewSection titleHeader="Contribution Details">
                                <span
                                    slot="edit-link"
                                    onClick={() => navigateTo(PAGE_INDICES.CONTRIBUTION_DETAILS)}
                                >
                                    <h6 className="small">
                                        <strong>EDIT</strong>
                                    </h6>
                                </span>
                                <div slot="section-body">
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Contribution type"
                                                value={
                                                    mVestData?.contributionDetails?.contribution_type
                                                }
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label={`${mVestData?.contributionDetails?.contribution_type == 'Monthly (MTN Users Only)' ? 'Monthly ' : '' } Contribution (Amount)`}
                                                value={mVestData?.contributionDetails?.monthly_contribution}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                </div>
                            </OmApplicationReviewSection>

                            {beneficiaryListData && beneficiaryListData.length > 0 && (
                                <OmApplicationReviewSection titleHeader="Beneficiary Details">
                                    <span
                                        slot="edit-link"
                                        onClick={() => navigateTo(PAGE_INDICES.BENEFICIARIES)}
                                    >
                                        <h6 className="small">
                                            <strong>EDIT</strong>
                                        </h6>
                                    </span>
                                    {beneficiaryListData.length > 1 ? (
                                        <div slot="section-body">
                                            {beneficiaryListData.map((beneficiary, index) => {
                                                return (
                                                    <div key={index}>
                                                        <OmdsGrid>
                                                            <OmdsCell span="row" span-md="6">
                                                                {' '}
                                                                <OmLabeledValueSummary
                                                                    isSensitive={true}
                                                                    className="beneficiary-name"
                                                                    label="Full name"
                                                                    value={joinName([
                                                                        beneficiary?.firstname,
                                                                        beneficiary?.OtherName,
                                                                    ])}
                                                                />
                                                            </OmdsCell>
                                                            <OmdsCell span="row" span-md="7..">
                                                                {' '}
                                                                <OmLabeledValueSummary
                                                                    className="beneficiary-split"
                                                                    label="Split"
                                                                    value={beneficiary?.perc_alloc + '%'}
                                                                />
                                                            </OmdsCell>
                                                        </OmdsGrid>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div slot="section-body">
                                            <OmdsGrid>
                                                <OmdsCell span="row" span-md="6">
                                                    <OmLabeledValueSummary
                                                        isSensitive={true}
                                                        className="beneficiary-name"
                                                        label="Full name"
                                                        value={joinName([
                                                            beneficiaryListData[0]?.firstname,
                                                            beneficiaryListData[0]?.OtherName,
                                                        ])}
                                                    />
                                                </OmdsCell>
                                            </OmdsGrid>
                                        </div>
                                    )}
                                </OmApplicationReviewSection>
                            )}
                        </div>
                    </div>
                </OmdsCell>
                <OmdsCell span="row" span-md="8..">
                    <OmApplicationSummaryDetailsCard>
                        <div slot="content">
                            <p>
                                <strong>Product summary</strong>
                            </p>
                            <h1 style={{ marginHeight: '10px' }}>GH₵ {mVestData?.contributionDetails?.monthly_contribution}</h1>
                            <p>Total monthly payment</p>
                            <div>
                                <div style={{ marginTop: '3rem' }}>
                                    <p>
                                        <strong>Member signature</strong>
                                    </p>
                                    <input type="file" accept={`.pdf, image/png, image/jpg, image/jpeg`} onInput={(e)=> handleFileInput(e, 'memberSignature')} className="omds-hidden" ref={memberSignatureInputFile} />  
                                    <OmdsFileUploadFile
                                        hasError={false}
                                        onCancelUpload={() => removeFile('memberSignature' )}
                                        onRemove={() => removeFile('memberSignature' )}
                                        onRetryUploading={(e) => handleFileInput(e, 'memberSignature')}
                                        onFileClick={ ()=> memberSignatureInputFile?.current?.click()}
                                        className="file-uploader-file"
                                    >
                                        {
                                            memberSignature !== null &&  <OmIcon className="file-upload-type-icon" slot='leading-icon' height={10} width={10} imageUrl={fileTypeIcons[memberSignatureFilename?.split('.').slice(-1)]}/>
                                        }
                                        { memberSignature !== null ? memberSignatureFilename : 'Upload Signature'}
                                        <div slot='status'>
                                            {getFileStatusText(memberSignature)}
                                        </div>
                                        {
                                            memberSignature === null && <OmdsIcon slot="suffix-icon" size={24} name="file_upload" className="file-upload-icon"></OmdsIcon>
                                        }
                                        { memberSignature !== null &&
                     <div slot="hint-text">
                         <OmFormError
                             message={
                                 memberSignature?.size > oneMB
                                     ? 'Max file size exceeded; Please, Signature should not be more than 1MB'
                                     : undefined
                             }
                         />
                     </div>
                 
                                        }
                                    </OmdsFileUploadFile>
        
                        
                                </div>
                                <div style={{ marginTop: '3rem' }}>
                                    <p>
                                        <strong>Member ID Card</strong>
                                    </p>
                                    <input type="file" accept={`.pdf, image/png, image/jpg, image/jpeg`} onInput={(e)=> handleFileInput(e, 'memberIdCard')} className="omds-hidden" ref={memberIdCardInputFile} />  
                                    <OmdsFileUploadFile
                                        hasError={false}
                                        onCancelUpload={() => removeFile('memberIdCard' )}
                                        onRemove={() => removeFile('memberIdCard' )}
                                        onRetryUploading={(e) => handleFileInput(e, 'memberIdCard')}
                                        onFileClick={ ()=> memberIdCardInputFile?.current?.click()}
                                        className="file-uploader-file"
                                    >
                                        {
                                            memberIdCard !== null && <OmIcon className="file-upload-type-icon"  slot='leading-icon' height={10} width={10} imageUrl={fileTypeIcons[memberIdCardFilename?.split('.').slice(-1)]}/>
                                        }
                                        { memberIdCard !== null ? memberIdCardFilename : 'Upload ID Card'}
                                        <div slot='status'>
                                            {getFileStatusText(memberIdCard)}
                                        </div>
                                        {
                                            memberIdCard === null && <OmdsIcon slot="suffix-icon" size={24} name="file_upload" className="file-upload-icon"></OmdsIcon>
                                        }
                                        { memberIdCard !== null &&
                 <div slot="hint-text"> <OmFormError
                     message={
                         memberIdCard?.size > oneMB
                             ? 'Max file size exceeded; Please, ID Card should not be more than 1MB'
                             : undefined
                     }
                 /></div>
                
                                        }
                                    </OmdsFileUploadFile>
                                </div>
                            </div>
                            <div style={{ marginTop: '3rem' }} className="check-box-container">
                                <OmCheckBox
                                    id="terms-and-conditions-checkbox"
                                    checked={hasAcceptedTermsAndCondition}
                                    onOnCheckBoxChanged={() => toggle()}
                                >
                                    <p slot="text-slot">
                      I accept the&nbsp;
                                        <a
                                            target="_blank" rel="noopener noreferrer"
                                            style={{color: '#50b848'}}
                                            href="https://eu-assets.contentstack.com/v3/assets/blta881ace037f02dd2/blt40f57782534a14cf/64d9e2e321c24dc1bc9462ae/Mvest_Scheme_Rules_-_Revised_2023.pdf"
              
                                        >
                        declarations.
                                        </a>
                                    </p>
                                </OmCheckBox>
                                <br />
                                <OmFormError
                                    message={
                                        hasAcceptedTermsAndCondition === true
                                            ? undefined
                                            : 'Please select checkbox before you can continue'
                                    }
                                />
                            </div>
                        </div>
                    </OmApplicationSummaryDetailsCard>
                </OmdsCell>
            </OmdsGrid>

            <SelectTelco open={paymentModal} onCloseHandler={() => togglePaymentModal()} />
            <OmModalLoader
                slot="loading-modal"
                loaderText="Please wait while we submit your data..."
                verticalAlignMiddle={true}
                open={mVestData?.schemeDetails?.loading}
            />
        </div>
    );
};

export default ReviewPage;
