import React from "react";
import { OmApplicationPopupModal } from "@om/component-library-react";
import OmButton from "../../../../../../../components/forms/OmButton";
import { ADDED_MAX_BENEFICIARIES } from "../../../../../ghana/configs/mvest-configs/config";

const MaxBeneficiariesAddedModal = () => {
  return (
    <OmApplicationPopupModal name="maxAdded" dismissible exitable>
      <div slot="content">
        <p>
          <strong>Unfortunately,</strong> you are only allowed to add up to{" "}
          {ADDED_MAX_BENEFICIARIES} beneficiaries online.
        </p>
      </div>
      <div slot="button">
        <OmButton type="primary" size="small" text="OKAY" />
      </div>
    </OmApplicationPopupModal>
  );
};

export default MaxBeneficiariesAddedModal;
