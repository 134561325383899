const CONTRIBUTION_TYPES = [
    // { fullcode: 'Monthly', description: 'Monthly (MTN Users Only)' },
    { fullcode: 'Lump Sum', description: 'Lump Sum (One-Off)' },
];

const CONTRIBUTION_TAX_STATUS = [
    { fullcode: 'Pre-tax', description: 'Pre-tax' },
    { fullcode: 'Post-tax', description: 'Post-tax' },
];

const SOURCE_OF_FUNDS = [
    { fullcode: 'Salary', description: 'Salary' },
    { fullcode: 'Investments', description: 'Investments' },
    { fullcode: 'Other', description: 'Other' },
];

const REDEMPTION = [
    { fullcode: '1-2 months', description: '1-2 months' },
    { fullcode: 'Anytime', description: 'Anytime' },
];

export {
    CONTRIBUTION_TYPES,
    CONTRIBUTION_TAX_STATUS,
    SOURCE_OF_FUNDS,
    REDEMPTION,
};
