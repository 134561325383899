export const PAGE_INDICES = {
    SCHEME_DETAILS: 0,
    CONTRIBUTION_DETAILS: 1,
    BENEFICIARIES: 2,
    BENEFICIARIES_SPLIT: 3,
    REVIEW_APPLICATION: 4,
    SUCCESS: 5,
};

export const PROGRESS_BAR_STEPS = [
    'Personal Details',
    'Contribution Details',
    'Beneficiaries Details',
    'Review Application',
].join('|');
